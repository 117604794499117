import React from "react";

const Home = () => {
	return (
		<React.Fragment>
            <div>
  <div
    className="framer-JILK8 framer-R9l6l framer-72rtr7"
    style={{
      minHeight: '100vh',
      width: 'auto'
    }}
  >
    <div
      className="framer-ybcxdl"
      name="Nav"
    >
      <div className="framer-19j5pt0-container">
        <div className="ssr-variant hidden-vh577v">
          <nav
            className="framer-erWEB framer-1u3qlpe framer-v-1u3qlpe"
            style={{
              backgroundColor: 'rgb(0, 0, 0)',
              width: '100%'
            }}
            tabIndex="0"
          >
            <div className="framer-17amz9i">
              <a
                className="framer-1ucqbv3 framer-1pw6atd"
                href="index.html#top"
              >
                <div
                  className="framer-1g2u208"
                  style={{
                    borderBottomLeftRadius: '999px',
                    borderBottomRightRadius: '999px',
                    borderTopLeftRadius: '999px',
                    borderTopRightRadius: '999px'
                  }}
                >
                  <div
                    style={{
                      borderRadius: 'inherit',
                      bottom: '0',
                      left: '0',
                      position: 'absolute',
                      right: '0',
                      top: '0'
                    }}
                  >
                    <img
                      alt=""
                      sizes="(min-width: 1793px) 24px, (min-width: 1049px) and (max-width: 1792px) 24px, (min-width: 810px) and (max-width: 1048px) 24px, (max-width: 809px) 24px"
                      src="../framerusercontent.com/images/GRDXXh32tWchzDZ7Azm5qwuWoM.png"
                      srcSet="/assets/images/bemoji.png?scale-down-to=1024 768w, /assets/images/bemoji.png?scale-down-to=2048 1536w, /assets/images/bemoji.png 2316w"
                      style={{
                        display: 'block',
                        height: '100%',
                        imageRendering: 'auto',
                        objectFit: 'cover',
                        objectPosition: 'center',
                        width: '100%'
                      }}
                    />
                  </div>
                </div>
                <div
                  className="framer-11aio73"
                  style={{
                    '--extracted-r6o4lv': 'rgb(255, 255, 255)',
                    '--framer-paragraph-spacing': '0px',
                    display: 'flex',
                    flexDirection: 'column',
                    flexShrink: '0',
                    justifyContent: 'flex-start',
                    outline: 'none',
                    transform: 'none'
                  }}
                >
                  <p
                    className="framer-text"
                    style={{
                      '--font-selector': 'R0Y7RmlndHJlZS1yZWd1bGFy',
                      '--framer-font-family': '"Figtree", "Figtree Placeholder", sans-serif',
                      '--framer-line-height': '100%',
                      '--framer-text-color': 'var(--extracted-r6o4lv, rgb(255, 255, 255))'
                    }}
                  >
                    Madiara Gassama ❖
                  </p>
                </div>
                <div
                  className="framer-119kvkj"
                  style={{
                    '--extracted-r6o4lv': 'rgba(255, 255, 255, 0.44)',
                    '--framer-paragraph-spacing': '0px',
                    display: 'flex',
                    flexDirection: 'column',
                    flexShrink: '0',
                    justifyContent: 'flex-start',
                    outline: 'none',
                    transform: 'none'
                  }}
                >
                  <p
                    className="framer-text"
                    style={{
                      '--font-selector': 'R0Y7RmlndHJlZS1yZWd1bGFy',
                      '--framer-font-family': '"Figtree", "Figtree Placeholder", sans-serif',
                      '--framer-font-size': '14px',
                      '--framer-line-height': '100%',
                      '--framer-text-color': 'var(--extracted-r6o4lv, rgba(255, 255, 255, 0.44))'
                    }}
                  >
                    @madiaragassama
                  </p>
                </div>
              </a>
              <div className="framer-1me9xfw">
                <div className="framer-i80qfs-container">
                  <div
                    style={{
                      display: 'contents'
                    }}
                  >
                    <a
                      className="framer-aFpzy framer-u56xug framer-v-rgs180 framer-6i8pib"
                      href=""
                      style={{
                        height: '100%'
                      }}
                      tabIndex="0"
                    >
                      <div
                        className="framer-18c5crn"
                        style={{
                          '--extracted-r6o4lv': 'rgba(255, 255, 255, 0.4)',
                          '--framer-paragraph-spacing': '0px',
                          display: 'flex',
                          flexDirection: 'column',
                          flexShrink: '0',
                          justifyContent: 'flex-start',
                          outline: 'none',
                          transform: 'none'
                        }}
                      >
                        <p
                          className="framer-text"
                          style={{
                            '--font-selector': 'R0Y7SW50ZXItNjAw',
                            '--framer-font-family': '"Inter", "Inter Placeholder", sans-serif',
                            '--framer-font-size': '14px',
                            '--framer-font-weight': '600',
                            '--framer-text-color': 'var(--extracted-r6o4lv, rgba(255, 255, 255, 0.4))'
                          }}
                        >
                          A propos
                        </p>
                      </div>
                    </a>
                  </div>
                </div>
                <div className="framer-1sqbzbe-container">
                  <div
                    style={{
                      display: 'contents'
                    }}
                  >
                    <a
                      className="framer-aFpzy framer-u56xug framer-v-2uutaf framer-6i8pib"
                      style={{
                        height: '100%'
                      }}
                      tabIndex="0"
                    >
                      <div
                        className="framer-18c5crn"
                        style={{
                          '--extracted-r6o4lv': 'rgba(255, 255, 255, 0.4)',
                          '--framer-paragraph-spacing': '0px',
                          display: 'flex',
                          flexDirection: 'column',
                          flexShrink: '0',
                          justifyContent: 'flex-start',
                          outline: 'none',
                          transform: 'none'
                        }}
                      >
                        <p
                          className="framer-text"
                          style={{
                            '--font-selector': 'R0Y7SW50ZXItNjAw',
                            '--framer-font-family': '"Inter", "Inter Placeholder", sans-serif',
                            '--framer-font-size': '14px',
                            '--framer-font-weight': '600',
                            '--framer-text-color': 'var(--extracted-r6o4lv, rgba(255, 255, 255, 0.4))'
                          }}
                        >
                          Contact
                        </p>
                      </div>
                    </a>
                  </div>
                </div>

                
              </div>
            </div>
          </nav>
        </div>
        <div className="ssr-variant hidden-175z5sy hidden-u21snj hidden-72rtr7">
          <nav
            className="framer-erWEB framer-1u3qlpe framer-v-bbtb4h"
            style={{
              backgroundColor: 'rgb(0, 0, 0)',
              width: '100%'
            }}
            tabIndex="0"
          >
            <div className="framer-17amz9i">
              <a
                className="framer-1ucqbv3 framer-1pw6atd"
                href="index.html#top"
              >
                <div
                  className="framer-1g2u208"
                  style={{
                    borderBottomLeftRadius: '999px',
                    borderBottomRightRadius: '999px',
                    borderTopLeftRadius: '999px',
                    borderTopRightRadius: '999px'
                  }}
                >
                  <div
                    style={{
                      borderRadius: 'inherit',
                      bottom: '0',
                      left: '0',
                      position: 'absolute',
                      right: '0',
                      top: '0'
                    }}
                  >
                    <img
                      alt=""
                      sizes="(min-width: 1793px) 24px, (min-width: 1049px) and (max-width: 1792px) 24px, (min-width: 810px) and (max-width: 1048px) 24px, (max-width: 809px) 24px"
                      src="../framerusercontent.com/images/GRDXXh32tWchzDZ7Azm5qwuWoM.png"
                      srcSet="/assets/images/bemoji.png?scale-down-to=1024 768w, /assets/images/bemoji.png?scale-down-to=2048 1536w, /assets/images/bemoji.png 2316w"
                      style={{
                        borderRadius: 'inherit',
                        display: 'block',
                        height: '100%',
                        imageRendering: 'auto',
                        objectFit: 'cover',
                        objectPosition: 'center',
                        width: '100%'
                      }}
                    />
                  </div>
                </div>
                <div
                  className="framer-11aio73"
                  style={{
                    '--extracted-r6o4lv': 'rgb(255, 255, 255)',
                    '--framer-paragraph-spacing': '0px',
                    display: 'flex',
                    flexDirection: 'column',
                    flexShrink: '0',
                    justifyContent: 'flex-start',
                    outline: 'none',
                    transform: 'none'
                  }}
                >
                  <p
                    className="framer-text"
                    style={{
                      '--font-selector': 'R0Y7RmlndHJlZS1yZWd1bGFy',
                      '--framer-font-family': '"Figtree", "Figtree Placeholder", sans-serif',
                      '--framer-line-height': '1.5em',
                      '--framer-text-alignment': 'left',
                      '--framer-text-color': 'var(--extracted-r6o4lv, rgb(255, 255, 255))'
                    }}
                  >
                    Madiara ❖
                  </p>
                </div>
                <div
                  className="framer-119kvkj"
                  style={{
                    '--extracted-r6o4lv': 'rgba(255, 255, 255, 0.44)',
                    '--framer-paragraph-spacing': '0px',
                    display: 'flex',
                    flexDirection: 'column',
                    flexShrink: '0',
                    justifyContent: 'flex-start',
                    outline: 'none',
                    transform: 'none'
                  }}
                >
                  <p
                    className="framer-text"
                    style={{
                      '--font-selector': 'R0Y7RmlndHJlZS1yZWd1bGFy',
                      '--framer-font-family': '"Figtree", "Figtree Placeholder", sans-serif',
                      '--framer-font-size': '14px',
                      '--framer-line-height': '100%',
                      '--framer-text-color': 'var(--extracted-r6o4lv, rgba(255, 255, 255, 0.44))'
                    }}
                  >
                    @madiaragassama
                  </p>
                </div>
              </a>
              <div
                className="framer-15gwohg"
                tabIndex="0"
              >
                <div
                  className="framer-1rci9pg"
                  style={{
                    backgroundColor: 'rgb(255, 255, 255)',
                    borderBottomLeftRadius: '10px',
                    borderBottomRightRadius: '10px',
                    borderTopLeftRadius: '10px',
                    borderTopRightRadius: '10px',
                    transform: 'none'
                  }}
                />
                <div
                  className="framer-71tnws"
                  style={{
                    backgroundColor: 'rgb(255, 255, 255)',
                    borderBottomLeftRadius: '10px',
                    borderBottomRightRadius: '10px',
                    borderTopLeftRadius: '10px',
                    borderTopRightRadius: '10px',
                    transform: 'none'
                  }}
                />
              </div>
            </div>
          </nav>
        </div>
      </div>
      <div
        className="framer-1aabs4n"
        name="Bottom Line"
      />
    </div>
    <div
      className="framer-1t4tks7"
      id="top"
      name="Container"
    >
      <div
        className="framer-dz7imv"
        name="Top"
      >
        <div className="ssr-variant hidden-175z5sy hidden-u21snj hidden-vh577v">
          <div
            className="framer-1f9u13o-container"
            style={{
              opacity: '0.001',
              transform: 'perspective(1200px) translateX(-32px) translateY(0px) scale(1) rotate(0deg) translateZ(0)'
            }}
          >
            <a
              className="framer-uviIJ framer-1a2vike framer-v-1a2vike framer-1wv0y5h"
              href="Cauri Money.html"
              style={{
                '--border-bottom-width': '1px',
                '--border-color': 'rgba(255, 255, 255, 0.1)',
                '--border-left-width': '1px',
                '--border-right-width': '1px',
                '--border-style': 'solid',
                '--border-top-width': '1px',
                backgroundColor: 'rgb(0, 0, 0)',
                borderBottomLeftRadius: '24px',
                borderBottomRightRadius: '24px',
                borderTopLeftRadius: '24px',
                borderTopRightRadius: '24px',
                height: '100%',
                width: '100%'
              }}
              tabIndex="0"
            >
              <div className="framer-19kjldf-container">
                <div
                  style={{
                    height: '100%',
                    overflow: 'hidden',
                    position: 'relative',
                    width: '100%'
                  }}
                >
                  <div
                    style={{
                      background: 'url(\'../framerusercontent.com/images/rR6HYXBrMmX4cRpXfXUOvpvpB0.png\')',
                      backgroundRepeat: 'repeat',
                      backgroundSize: '256px 256px',
                      height: '400%',
                      inset: '-200%',
                      opacity: '0.02',
                      position: 'absolute',
                      width: '400%'
                    }}
                   />
                </div>
              </div>
              <div
                className="framer-3tnfe8"
                style={{
                  WebkitFilter: 'blur(32px)',
                  backgroundColor: 'rgb(0, 0, 0)',
                  filter: 'blur(32px)'
                }}
              >
                <div className="framer-5tbvbw">
                  <div
                    style={{
                      borderRadius: 'inherit',
                      bottom: '0',
                      left: '0',
                      position: 'absolute',
                      right: '0',
                      top: '0'
                    }}
                  >
                    <img
                      alt=""
                      sizes="(min-width: 1049px) and (max-width: 1792px) calc(min(184px, 100vw) * 1.2011), (min-width: 810px) and (max-width: 1048px) calc(min(184px, 100vw) * 1.2011), (min-width: 1793px) calc(min(184px, 100vw) * 1.2011), (max-width: 809px) calc(min(184px, 100vw) * 1.2011)"
                      src="/assets/images/ecYMpoj99QjfLomGlFDpi6Haos.png"
                      srcSet="/assets/images/ecYMpoj99QjfLomGlFDpi6Haos.png?scale-down-to=512 512w, /assets/images/ecYMpoj99QjfLomGlFDpi6Haos.png?scale-down-to=1024 1024w, /assets/images/ecYMpoj99QjfLomGlFDpi6Haos.png?scale-down-to=2048 2048w, /assets/images/ecYMpoj99QjfLomGlFDpi6Haos.png 3240w"
                      style={{
                        borderRadius: 'inherit',
                        display: 'block',
                        height: '100%',
                        imageRendering: 'auto',
                        objectFit: 'cover',
                        objectPosition: 'center',
                        width: '100%'
                      }}
                    />
                  </div>
                </div>
                <div className="framer-1r8l7tp-container">
                  <video
                    loop
                    muted
                    playsInline
                    src="/assets/videos/video-cauri-money.mp4"
                    style={{
                      backgroundColor: 'rgba(0, 0, 0, 0)',
                      borderRadius: '0px',
                      cursor: 'auto',
                      display: 'block',
                      height: '100%',
                      objectFit: 'cover',
                      objectPosition: '50% 50%',
                      width: '100%'
                    }}
                  />
                </div>
              </div>
              <div className="framer-1axnin4">
                <div
                  className="framer-btp5e4"
                  style={{
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    borderBottomLeftRadius: '12px',
                    borderBottomRightRadius: '12px',
                    borderTopLeftRadius: '12px',
                    borderTopRightRadius: '12px'
                  }}
                >
                  <div
                    className="framer-1o5m695"
                    style={{
                      borderBottomLeftRadius: '8px',
                      borderBottomRightRadius: '8px',
                      borderTopLeftRadius: '8px',
                      borderTopRightRadius: '8px'
                    }}
                  >
                    <div
                      style={{
                        borderRadius: 'inherit',
                        bottom: '0',
                        left: '0',
                        position: 'absolute',
                        right: '0',
                        top: '0'
                      }}
                    >
                      <img
                        alt=""
                        sizes="40px"
                        src="../framerusercontent.com/images/WtVKjsTyatNDYjhn5rZptLnzw0w.png"
                        style={{
                          borderRadius: 'inherit',
                          display: 'block',
                          height: '100%',
                          imageRendering: 'auto',
                          objectFit: 'cover',
                          objectPosition: 'center',
                          width: '100%'
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div className="ssr-variant hidden-u21snj hidden-vh577v hidden-72rtr7">
          <div
            className="framer-1f9u13o-container"
            style={{
              opacity: '0.001',
              transform: 'perspective(1200px) translateX(-32px) translateY(0px) scale(1) rotate(0deg) translateZ(0)'
            }}
          >
            <a
              className="framer-uviIJ framer-1a2vike framer-v-1a2vike framer-1wv0y5h"
              href="Cauri Money.html"
              style={{
                '--border-bottom-width': '1px',
                '--border-color': 'rgba(255, 255, 255, 0.1)',
                '--border-left-width': '1px',
                '--border-right-width': '1px',
                '--border-style': 'solid',
                '--border-top-width': '1px',
                backgroundColor: 'rgb(0, 0, 0)',
                borderBottomLeftRadius: '24px',
                borderBottomRightRadius: '24px',
                borderTopLeftRadius: '24px',
                borderTopRightRadius: '24px',
                height: '100%',
                width: '100%'
              }}
              tabIndex="0"
            >
              <div className="framer-19kjldf-container">
                <div
                  style={{
                    height: '100%',
                    overflow: 'hidden',
                    position: 'relative',
                    width: '100%'
                  }}
                >
                  <div
                    style={{
                      background: 'url(\'../framerusercontent.com/images/rR6HYXBrMmX4cRpXfXUOvpvpB0.png\')',
                      backgroundRepeat: 'repeat',
                      backgroundSize: '256px 256px',
                      height: '400%',
                      inset: '-200%',
                      opacity: '0.02',
                      position: 'absolute',
                      width: '400%'
                    }}
                   />
                </div>
              </div>
              <div
                className="framer-3tnfe8"
                style={{
                  WebkitFilter: 'blur(32px)',
                  backgroundColor: 'rgb(0, 0, 0)',
                  filter: 'blur(32px)'
                }}
              >
                <div className="framer-5tbvbw">
                  <div
                    style={{
                      borderRadius: 'inherit',
                      bottom: '0',
                      left: '0',
                      position: 'absolute',
                      right: '0',
                      top: '0'
                    }}
                  >
                    <img
                      alt=""
                      sizes="(min-width: 1049px) and (max-width: 1792px) calc(min(184px, 100vw) * 1.2011), (min-width: 810px) and (max-width: 1048px) calc(min(184px, 100vw) * 1.2011), (min-width: 1793px) calc(min(184px, 100vw) * 1.2011), (max-width: 809px) calc(min(184px, 100vw) * 1.2011)"
                      src="/assets/images/ecYMpoj99QjfLomGlFDpi6Haos.png"
                      srcSet="/assets/images/ecYMpoj99QjfLomGlFDpi6Haos.png?scale-down-to=512 512w, /assets/images/ecYMpoj99QjfLomGlFDpi6Haos.png?scale-down-to=1024 1024w, /assets/images/ecYMpoj99QjfLomGlFDpi6Haos.png?scale-down-to=2048 2048w, /assets/images/ecYMpoj99QjfLomGlFDpi6Haos.png 3240w"
                      style={{
                        borderRadius: 'inherit',
                        display: 'block',
                        height: '100%',
                        imageRendering: 'auto',
                        objectFit: 'cover',
                        objectPosition: 'center',
                        width: '100%'
                      }}
                    />
                  </div>
                </div>
                <div className="framer-1r8l7tp-container">
                  <video
                    loop
                    muted
                    playsInline
                    src="/assets/videos/video-cauri-money.mp4"
                    style={{
                      backgroundColor: 'rgba(0, 0, 0, 0)',
                      borderRadius: '0px',
                      cursor: 'auto',
                      display: 'block',
                      height: '100%',
                      objectFit: 'cover',
                      objectPosition: '50% 50%',
                      width: '100%'
                    }}
                  />
                </div>
              </div>
              <div className="framer-1axnin4">
                <div
                  className="framer-btp5e4"
                  style={{
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    borderBottomLeftRadius: '12px',
                    borderBottomRightRadius: '12px',
                    borderTopLeftRadius: '12px',
                    borderTopRightRadius: '12px'
                  }}
                >
                  <div
                    className="framer-1o5m695"
                    style={{
                      borderBottomLeftRadius: '8px',
                      borderBottomRightRadius: '8px',
                      borderTopLeftRadius: '8px',
                      borderTopRightRadius: '8px'
                    }}
                  >
                    <div
                      style={{
                        borderRadius: 'inherit',
                        bottom: '0',
                        left: '0',
                        position: 'absolute',
                        right: '0',
                        top: '0'
                      }}
                    >
                      <img
                        alt=""
                        sizes="40px"
                        src="../framerusercontent.com/images/WtVKjsTyatNDYjhn5rZptLnzw0w.png"
                        style={{
                          borderRadius: 'inherit',
                          display: 'block',
                          height: '100%',
                          imageRendering: 'auto',
                          objectFit: 'cover',
                          objectPosition: 'center',
                          width: '100%'
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div className="ssr-variant hidden-175z5sy hidden-vh577v hidden-72rtr7">
          <div
            className="framer-1f9u13o-container"
            style={{
              opacity: '0.001',
              transform: 'perspective(1200px) translateX(-32px) translateY(0px) scale(1) rotate(0deg) translateZ(0)'
            }}
          >
            <a
              className="framer-uviIJ framer-1a2vike framer-v-1a2vike framer-1wv0y5h"
              href="Cauri Money.html"
              style={{
                '--border-bottom-width': '1px',
                '--border-color': 'rgba(255, 255, 255, 0.1)',
                '--border-left-width': '1px',
                '--border-right-width': '1px',
                '--border-style': 'solid',
                '--border-top-width': '1px',
                backgroundColor: 'rgb(0, 0, 0)',
                borderBottomLeftRadius: '24px',
                borderBottomRightRadius: '24px',
                borderTopLeftRadius: '24px',
                borderTopRightRadius: '24px',
                height: '100%',
                width: '100%'
              }}
              tabIndex="0"
            >
              <div className="framer-19kjldf-container">
                <div
                  style={{
                    height: '100%',
                    overflow: 'hidden',
                    position: 'relative',
                    width: '100%'
                  }}
                >
                  <div
                    style={{
                      background: 'url(\'../framerusercontent.com/images/rR6HYXBrMmX4cRpXfXUOvpvpB0.png\')',
                      backgroundRepeat: 'repeat',
                      backgroundSize: '256px 256px',
                      height: '400%',
                      inset: '-200%',
                      opacity: '0.02',
                      position: 'absolute',
                      width: '400%'
                    }}
                   />
                </div>
              </div>
              <div
                className="framer-3tnfe8"
                style={{
                  WebkitFilter: 'blur(32px)',
                  backgroundColor: 'rgb(0, 0, 0)',
                  filter: 'blur(32px)'
                }}
              >
                <div className="framer-5tbvbw">
                  <div
                    style={{
                      borderRadius: 'inherit',
                      bottom: '0',
                      left: '0',
                      position: 'absolute',
                      right: '0',
                      top: '0'
                    }}
                  >
                    <img
                      alt=""
                      sizes="(min-width: 1049px) and (max-width: 1792px) calc(min(184px, 100vw) * 1.2011), (min-width: 810px) and (max-width: 1048px) calc(min(184px, 100vw) * 1.2011), (min-width: 1793px) calc(min(184px, 100vw) * 1.2011), (max-width: 809px) calc(min(184px, 100vw) * 1.2011)"
                      src="/assets/images/ecYMpoj99QjfLomGlFDpi6Haos.png"
                      srcSet="/assets/images/ecYMpoj99QjfLomGlFDpi6Haos.png?scale-down-to=512 512w, /assets/images/ecYMpoj99QjfLomGlFDpi6Haos.png?scale-down-to=1024 1024w, /assets/images/ecYMpoj99QjfLomGlFDpi6Haos.png?scale-down-to=2048 2048w, /assets/images/ecYMpoj99QjfLomGlFDpi6Haos.png 3240w"
                      style={{
                        borderRadius: 'inherit',
                        display: 'block',
                        height: '100%',
                        imageRendering: 'auto',
                        objectFit: 'cover',
                        objectPosition: 'center',
                        width: '100%'
                      }}
                    />
                  </div>
                </div>
                <div className="framer-1r8l7tp-container">
                  <video
                    loop
                    muted
                    playsInline
                    src="/assets/videos/video-cauri-money.mp4"
                    style={{
                      backgroundColor: 'rgba(0, 0, 0, 0)',
                      borderRadius: '0px',
                      cursor: 'auto',
                      display: 'block',
                      height: '100%',
                      objectFit: 'cover',
                      objectPosition: '50% 50%',
                      width: '100%'
                    }}
                  />
                </div>
              </div>
              <div className="framer-1axnin4">
                <div
                  className="framer-btp5e4"
                  style={{
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    borderBottomLeftRadius: '12px',
                    borderBottomRightRadius: '12px',
                    borderTopLeftRadius: '12px',
                    borderTopRightRadius: '12px'
                  }}
                >
                  <div
                    className="framer-1o5m695"
                    style={{
                      borderBottomLeftRadius: '8px',
                      borderBottomRightRadius: '8px',
                      borderTopLeftRadius: '8px',
                      borderTopRightRadius: '8px'
                    }}
                  >
                    <div
                      style={{
                        borderRadius: 'inherit',
                        bottom: '0',
                        left: '0',
                        position: 'absolute',
                        right: '0',
                        top: '0'
                      }}
                    >
                      <img
                        alt=""
                        sizes="40px"
                        src="../framerusercontent.com/images/WtVKjsTyatNDYjhn5rZptLnzw0w.png"
                        style={{
                          borderRadius: 'inherit',
                          display: 'block',
                          height: '100%',
                          imageRendering: 'auto',
                          objectFit: 'cover',
                          objectPosition: 'center',
                          width: '100%'
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div className="ssr-variant hidden-175z5sy hidden-u21snj hidden-72rtr7">
          <div
            className="framer-1f9u13o-container"
            style={{
              opacity: '0.001',
              transform: 'perspective(1200px) translateX(-32px) translateY(0px) scale(1) rotate(0deg) translateZ(0)'
            }}
          >
            <a
              className="framer-uviIJ framer-1a2vike framer-v-4kcy1m framer-1wv0y5h"
              style={{
                '--border-bottom-width': '1px',
                '--border-color': 'rgba(255, 255, 255, 0.1)',
                '--border-left-width': '1px',
                '--border-right-width': '1px',
                '--border-style': 'solid',
                '--border-top-width': '1px',
                backgroundColor: 'rgb(0, 0, 0)',
                borderBottomLeftRadius: '24px',
                borderBottomRightRadius: '24px',
                borderTopLeftRadius: '24px',
                borderTopRightRadius: '24px',
                height: '100%',
                width: '100%'
              }}
              tabIndex="0"
            >
              <div className="framer-19kjldf-container">
                <div
                  style={{
                    height: '100%',
                    overflow: 'hidden',
                    position: 'relative',
                    width: '100%'
                  }}
                >
                  <div
                    style={{
                      background: 'url(\'../framerusercontent.com/images/rR6HYXBrMmX4cRpXfXUOvpvpB0.png\')',
                      backgroundRepeat: 'repeat',
                      backgroundSize: '256px 256px',
                      height: '400%',
                      inset: '-200%',
                      opacity: '0.02',
                      position: 'absolute',
                      width: '400%'
                    }}
                   />
                </div>
              </div>
              <div
                className="framer-3tnfe8"
                style={{
                  WebkitFilter: 'blur(32px)',
                  backgroundColor: 'rgb(0, 0, 0)',
                  filter: 'blur(32px)'
                }}
              >
                <div className="framer-5tbvbw">
                  <div
                    style={{
                      borderRadius: 'inherit',
                      bottom: '0',
                      left: '0',
                      position: 'absolute',
                      right: '0',
                      top: '0'
                    }}
                  >
                    <img
                      alt=""
                      sizes="(min-width: 1049px) and (max-width: 1792px) calc(min(184px, 100vw) * 1.2011), (min-width: 810px) and (max-width: 1048px) calc(min(184px, 100vw) * 1.2011), (min-width: 1793px) calc(min(184px, 100vw) * 1.2011), (max-width: 809px) calc(min(184px, 100vw) * 1.2011)"
                      src="/assets/images/ecYMpoj99QjfLomGlFDpi6Haos.png"
                      srcSet="/assets/images/ecYMpoj99QjfLomGlFDpi6Haos.png?scale-down-to=512 512w, /assets/images/ecYMpoj99QjfLomGlFDpi6Haos.png?scale-down-to=1024 1024w, /assets/images/ecYMpoj99QjfLomGlFDpi6Haos.png?scale-down-to=2048 2048w, /assets/images/ecYMpoj99QjfLomGlFDpi6Haos.png 3240w"
                      style={{
                        borderRadius: 'inherit',
                        display: 'block',
                        height: '100%',
                        imageRendering: 'auto',
                        objectFit: 'cover',
                        objectPosition: 'center',
                        width: '100%'
                      }}
                    />
                  </div>
                </div>
                <div className="framer-1r8l7tp-container">
                  <video
                    loop
                    muted
                    playsInline
                    src="https://framerusercontent.com/assets/bgAJCmuU41H4dziwIBFHR475Ju0.mp4"
                    style={{
                      backgroundColor: 'rgba(0, 0, 0, 0)',
                      borderRadius: '0px',
                      cursor: 'auto',
                      display: 'block',
                      height: '100%',
                      objectFit: 'cover',
                      objectPosition: '50% 50%',
                      width: '100%'
                    }}
                  />
                </div>
              </div>
              <div className="framer-1axnin4">
                <div
                  className="framer-btp5e4"
                  style={{
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    borderBottomLeftRadius: '12px',
                    borderBottomRightRadius: '12px',
                    borderTopLeftRadius: '12px',
                    borderTopRightRadius: '12px'
                  }}
                >
                  <div
                    className="framer-1o5m695"
                    style={{
                      borderBottomLeftRadius: '8px',
                      borderBottomRightRadius: '8px',
                      borderTopLeftRadius: '8px',
                      borderTopRightRadius: '8px'
                    }}
                  >
                    <div
                      style={{
                        borderRadius: 'inherit',
                        bottom: '0',
                        left: '0',
                        position: 'absolute',
                        right: '0',
                        top: '0'
                      }}
                    >
                      <img
                        alt=""
                        sizes="40px"
                        src="../framerusercontent.com/images/WtVKjsTyatNDYjhn5rZptLnzw0w.png"
                        style={{
                          borderRadius: 'inherit',
                          display: 'block',
                          height: '100%',
                          imageRendering: 'auto',
                          objectFit: 'cover',
                          objectPosition: 'center',
                          width: '100%'
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div className="framer-fxra0t">
          <div className="ssr-variant hidden-175z5sy hidden-u21snj hidden-vh577v">
            <div
              className="framer-1miilmz-container"
              style={{
                opacity: '0.001',
                transform: 'perspective(1200px) translateX(0px) translateY(-32px) scale(1) rotate(0deg) translateZ(0)'
              }}
            >
              <a
                className="framer-uviIJ framer-1a2vike framer-v-1a2vike framer-1wv0y5h"
                href="#"
                style={{
                  '--border-bottom-width': '1px',
                  '--border-color': 'rgba(255, 255, 255, 0.1)',
                  '--border-left-width': '1px',
                  '--border-right-width': '1px',
                  '--border-style': 'solid',
                  '--border-top-width': '1px',
                  backgroundColor: 'rgb(0, 0, 0)',
                  borderBottomLeftRadius: '24px',
                  borderBottomRightRadius: '24px',
                  borderTopLeftRadius: '24px',
                  borderTopRightRadius: '24px',
                  height: '100%',
                  width: '100%'
                }}
                tabIndex="0"
              >
                <div className="framer-19kjldf-container">
                  <div
                    style={{
                      height: '100%',
                      overflow: 'hidden',
                      position: 'relative',
                      width: '100%'
                    }}
                  >
                    <div
                      style={{
                        background: 'url(\'../framerusercontent.com/images/rR6HYXBrMmX4cRpXfXUOvpvpB0.png\')',
                        backgroundRepeat: 'repeat',
                        backgroundSize: '256px 256px',
                        height: '400%',
                        inset: '-200%',
                        opacity: '0.02',
                        position: 'absolute',
                        width: '400%'
                      }}
                     />
                  </div>
                </div>
                <div
                  className="framer-3tnfe8"
                  style={{
                    WebkitFilter: 'blur(32px)',
                    backgroundColor: 'rgb(0, 0, 0)',
                    filter: 'blur(32px)'
                  }}
                >
                  <div className="framer-5tbvbw">
                    <div
                      style={{
                        borderRadius: 'inherit',
                        bottom: '0',
                        left: '0',
                        position: 'absolute',
                        right: '0',
                        top: '0'
                      }}
                    >
                      <img
                        alt=""
                        sizes="(min-width: 1049px) and (max-width: 1792px) calc(min(184px, 100vw) * 1.2011), (min-width: 810px) and (max-width: 1048px) calc(min(184px, 100vw) * 1.2011), (min-width: 1793px) calc(min(184px, 100vw) * 1.2011)"
                        src="/assets/images/7VaK0uQmsWfdOHI4INLrz0Y0.png"
                        srcSet="/assets/images/7VaK0uQmsWfdOHI4INLrz0Y0.png?scale-down-to=512 512w, /assets/images/7VaK0uQmsWfdOHI4INLrz0Y0.png?scale-down-to=1024 1024w, /assets/images/7VaK0uQmsWfdOHI4INLrz0Y0.png 1824w"
                        style={{
                          borderRadius: 'inherit',
                          display: 'block',
                          height: '100%',
                          imageRendering: 'auto',
                          objectFit: 'cover',
                          objectPosition: 'center',
                          width: '100%'
                        }}
                      />
                    </div>
                  </div>
                  <div className="framer-1r8l7tp-container">
                    <video
                      loop
                      muted
                      playsInline
                      src="/assets/videos/video-enovel-digital.mp4"
                      style={{
                        backgroundColor: 'rgba(0, 0, 0, 0)',
                        borderRadius: '0px',
                        cursor: 'auto',
                        display: 'block',
                        height: '100%',
                        objectFit: 'cover',
                        objectPosition: '50% 50%',
                        width: '100%'
                      }}
                    />
                  </div>
                </div>
                <div className="framer-1axnin4">
                  <div
                    className="framer-btp5e4"
                    style={{
                      backgroundColor: 'rgba(255, 255, 255, 0.1)',
                      borderBottomLeftRadius: '12px',
                      borderBottomRightRadius: '12px',
                      borderTopLeftRadius: '12px',
                      borderTopRightRadius: '12px'
                    }}
                  >
                    <div
                      className="framer-1o5m695"
                      style={{
                        borderBottomLeftRadius: '8px',
                        borderBottomRightRadius: '8px',
                        borderTopLeftRadius: '8px',
                        borderTopRightRadius: '8px'
                      }}
                    >
                      <div
                        style={{
                          borderRadius: 'inherit',
                          bottom: '0',
                          left: '0',
                          position: 'absolute',
                          right: '0',
                          top: '0'
                        }}
                      >
                        <img
                          alt=""
                          sizes="40px"
                          src="../framerusercontent.com/images/eRNoJZEYtKnUYJm9TmytZYiwiVg.png"
                          style={{
                            borderRadius: 'inherit',
                            display: 'block',
                            height: '100%',
                            imageRendering: 'auto',
                            objectFit: 'cover',
                            objectPosition: 'center',
                            width: '100%'
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <div className="ssr-variant hidden-u21snj hidden-vh577v hidden-72rtr7">
            <div
              className="framer-1miilmz-container"
              style={{
                opacity: '0.001',
                transform: 'perspective(1200px) translateX(0px) translateY(-32px) scale(1) rotate(0deg) translateZ(0)'
              }}
            >
              <a
                className="framer-uviIJ framer-1a2vike framer-v-1a2vike framer-1wv0y5h"
                href="#"
                style={{
                  '--border-bottom-width': '1px',
                  '--border-color': 'rgba(255, 255, 255, 0.1)',
                  '--border-left-width': '1px',
                  '--border-right-width': '1px',
                  '--border-style': 'solid',
                  '--border-top-width': '1px',
                  backgroundColor: 'rgb(0, 0, 0)',
                  borderBottomLeftRadius: '24px',
                  borderBottomRightRadius: '24px',
                  borderTopLeftRadius: '24px',
                  borderTopRightRadius: '24px',
                  height: '100%',
                  width: '100%'
                }}
                tabIndex="0"
              >
                <div className="framer-19kjldf-container">
                  <div
                    style={{
                      height: '100%',
                      overflow: 'hidden',
                      position: 'relative',
                      width: '100%'
                    }}
                  >
                    <div
                      style={{
                        background: 'url(\'../framerusercontent.com/images/rR6HYXBrMmX4cRpXfXUOvpvpB0.png\')',
                        backgroundRepeat: 'repeat',
                        backgroundSize: '256px 256px',
                        height: '400%',
                        inset: '-200%',
                        opacity: '0.02',
                        position: 'absolute',
                        width: '400%'
                      }}
                     />
                  </div>
                </div>
                <div
                  className="framer-3tnfe8"
                  style={{
                    WebkitFilter: 'blur(32px)',
                    backgroundColor: 'rgb(0, 0, 0)',
                    filter: 'blur(32px)'
                  }}
                >
                  <div className="framer-5tbvbw">
                    <div
                      style={{
                        borderRadius: 'inherit',
                        bottom: '0',
                        left: '0',
                        position: 'absolute',
                        right: '0',
                        top: '0'
                      }}
                    >
                      <img
                        alt=""
                        sizes="(min-width: 1049px) and (max-width: 1792px) calc(min(184px, 100vw) * 1.2011), (min-width: 810px) and (max-width: 1048px) calc(min(184px, 100vw) * 1.2011), (min-width: 1793px) calc(min(184px, 100vw) * 1.2011)"
                        src="/assets/images/7VaK0uQmsWfdOHI4INLrz0Y0.png"
                        srcSet="/assets/images/7VaK0uQmsWfdOHI4INLrz0Y0.png?scale-down-to=512 512w, /assets/images/7VaK0uQmsWfdOHI4INLrz0Y0.png?scale-down-to=1024 1024w, /assets/images/7VaK0uQmsWfdOHI4INLrz0Y0.png 1824w"
                        style={{
                          borderRadius: 'inherit',
                          display: 'block',
                          height: '100%',
                          imageRendering: 'auto',
                          objectFit: 'cover',
                          objectPosition: 'center',
                          width: '100%'
                        }}
                      />
                    </div>
                  </div>
                  <div className="framer-1r8l7tp-container">
                    <video
                      loop
                      muted
                      playsInline
                      src="/assets/videos/video-enovel-digital.mp4"
                      style={{
                        backgroundColor: 'rgba(0, 0, 0, 0)',
                        borderRadius: '0px',
                        cursor: 'auto',
                        display: 'block',
                        height: '100%',
                        objectFit: 'cover',
                        objectPosition: '50% 50%',
                        width: '100%'
                      }}
                    />
                  </div>
                </div>
                <div className="framer-1axnin4">
                  <div
                    className="framer-btp5e4"
                    style={{
                      backgroundColor: 'rgba(255, 255, 255, 0.1)',
                      borderBottomLeftRadius: '12px',
                      borderBottomRightRadius: '12px',
                      borderTopLeftRadius: '12px',
                      borderTopRightRadius: '12px'
                    }}
                  >
                    <div
                      className="framer-1o5m695"
                      style={{
                        borderBottomLeftRadius: '8px',
                        borderBottomRightRadius: '8px',
                        borderTopLeftRadius: '8px',
                        borderTopRightRadius: '8px'
                      }}
                    >
                      <div
                        style={{
                          borderRadius: 'inherit',
                          bottom: '0',
                          left: '0',
                          position: 'absolute',
                          right: '0',
                          top: '0'
                        }}
                      >
                        <img
                          alt=""
                          sizes="40px"
                          src="../framerusercontent.com/images/eRNoJZEYtKnUYJm9TmytZYiwiVg.png"
                          style={{
                            borderRadius: 'inherit',
                            display: 'block',
                            height: '100%',
                            imageRendering: 'auto',
                            objectFit: 'cover',
                            objectPosition: 'center',
                            width: '100%'
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <div className="ssr-variant hidden-175z5sy hidden-vh577v hidden-72rtr7">
            <div
              className="framer-1miilmz-container"
              style={{
                opacity: '0.001',
                transform: 'perspective(1200px) translateX(0px) translateY(-32px) scale(1) rotate(0deg) translateZ(0)'
              }}
            >
              <a
                className="framer-uviIJ framer-1a2vike framer-v-1a2vike framer-1wv0y5h"
                href="#"
                style={{
                  '--border-bottom-width': '1px',
                  '--border-color': 'rgba(255, 255, 255, 0.1)',
                  '--border-left-width': '1px',
                  '--border-right-width': '1px',
                  '--border-style': 'solid',
                  '--border-top-width': '1px',
                  backgroundColor: 'rgb(0, 0, 0)',
                  borderBottomLeftRadius: '24px',
                  borderBottomRightRadius: '24px',
                  borderTopLeftRadius: '24px',
                  borderTopRightRadius: '24px',
                  height: '100%',
                  width: '100%'
                }}
                tabIndex="0"
              >
                <div className="framer-19kjldf-container">
                  <div
                    style={{
                      height: '100%',
                      overflow: 'hidden',
                      position: 'relative',
                      width: '100%'
                    }}
                  >
                    <div
                      style={{
                        background: 'url(\'../framerusercontent.com/images/rR6HYXBrMmX4cRpXfXUOvpvpB0.png\')',
                        backgroundRepeat: 'repeat',
                        backgroundSize: '256px 256px',
                        height: '400%',
                        inset: '-200%',
                        opacity: '0.02',
                        position: 'absolute',
                        width: '400%'
                      }}
                     />
                  </div>
                </div>
                <div
                  className="framer-3tnfe8"
                  style={{
                    WebkitFilter: 'blur(32px)',
                    backgroundColor: 'rgb(0, 0, 0)',
                    filter: 'blur(32px)'
                  }}
                >
                  <div className="framer-5tbvbw">
                    <div
                      style={{
                        borderRadius: 'inherit',
                        bottom: '0',
                        left: '0',
                        position: 'absolute',
                        right: '0',
                        top: '0'
                      }}
                    >
                      <img
                        alt=""
                        sizes="(min-width: 1049px) and (max-width: 1792px) calc(min(184px, 100vw) * 1.2011), (min-width: 810px) and (max-width: 1048px) calc(min(184px, 100vw) * 1.2011), (min-width: 1793px) calc(min(184px, 100vw) * 1.2011)"
                        src="/assets/images/7VaK0uQmsWfdOHI4INLrz0Y0.png"
                        srcSet="/assets/images/7VaK0uQmsWfdOHI4INLrz0Y0.png?scale-down-to=512 512w, /assets/images/7VaK0uQmsWfdOHI4INLrz0Y0.png?scale-down-to=1024 1024w, /assets/images/7VaK0uQmsWfdOHI4INLrz0Y0.png 1824w"
                        style={{
                          borderRadius: 'inherit',
                          display: 'block',
                          height: '100%',
                          imageRendering: 'auto',
                          objectFit: 'cover',
                          objectPosition: 'center',
                          width: '100%'
                        }}
                      />
                    </div>
                  </div>
                  <div className="framer-1r8l7tp-container">
                    <video
                      loop
                      muted
                      playsInline
                      src="/assets/videos/video-enovel-digital.mp4"
                      style={{
                        backgroundColor: 'rgba(0, 0, 0, 0)',
                        borderRadius: '0px',
                        cursor: 'auto',
                        display: 'block',
                        height: '100%',
                        objectFit: 'cover',
                        objectPosition: '50% 50%',
                        width: '100%'
                      }}
                    />
                  </div>
                </div>
                <div className="framer-1axnin4">
                  <div
                    className="framer-btp5e4"
                    style={{
                      backgroundColor: 'rgba(255, 255, 255, 0.1)',
                      borderBottomLeftRadius: '12px',
                      borderBottomRightRadius: '12px',
                      borderTopLeftRadius: '12px',
                      borderTopRightRadius: '12px'
                    }}
                  >
                    <div
                      className="framer-1o5m695"
                      style={{
                        borderBottomLeftRadius: '8px',
                        borderBottomRightRadius: '8px',
                        borderTopLeftRadius: '8px',
                        borderTopRightRadius: '8px'
                      }}
                    >
                      <div
                        style={{
                          borderRadius: 'inherit',
                          bottom: '0',
                          left: '0',
                          position: 'absolute',
                          right: '0',
                          top: '0'
                        }}
                      >
                        <img
                          alt=""
                          sizes="40px"
                          src="../framerusercontent.com/images/eRNoJZEYtKnUYJm9TmytZYiwiVg.png"
                          style={{
                            borderRadius: 'inherit',
                            display: 'block',
                            height: '100%',
                            imageRendering: 'auto',
                            objectFit: 'cover',
                            objectPosition: 'center',
                            width: '100%'
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <div className="ssr-variant hidden-175z5sy hidden-u21snj hidden-72rtr7">
            <div
              className="framer-1miilmz-container"
              style={{
                opacity: '0.001',
                transform: 'perspective(1200px) translateX(0px) translateY(32px) scale(1) rotate(0deg) translateZ(0)'
              }}
            >
              <a
                className="framer-uviIJ framer-1a2vike framer-v-4kcy1m framer-1wv0y5h"
                style={{
                  '--border-bottom-width': '1px',
                  '--border-color': 'rgba(255, 255, 255, 0.1)',
                  '--border-left-width': '1px',
                  '--border-right-width': '1px',
                  '--border-style': 'solid',
                  '--border-top-width': '1px',
                  backgroundColor: 'rgb(0, 0, 0)',
                  borderBottomLeftRadius: '24px',
                  borderBottomRightRadius: '24px',
                  borderTopLeftRadius: '24px',
                  borderTopRightRadius: '24px',
                  height: '100%',
                  width: '100%'
                }}
                tabIndex="0"
              >
                <div className="framer-19kjldf-container">
                  <div
                    style={{
                      height: '100%',
                      overflow: 'hidden',
                      position: 'relative',
                      width: '100%'
                    }}
                  >
                    <div
                      style={{
                        background: 'url(\'../framerusercontent.com/images/rR6HYXBrMmX4cRpXfXUOvpvpB0.png\')',
                        backgroundRepeat: 'repeat',
                        backgroundSize: '256px 256px',
                        height: '400%',
                        inset: '-200%',
                        opacity: '0.02',
                        position: 'absolute',
                        width: '400%'
                      }}
                     />
                  </div>
                </div>
                <div
                  className="framer-3tnfe8"
                  style={{
                    WebkitFilter: 'blur(32px)',
                    backgroundColor: 'rgb(0, 0, 0)',
                    filter: 'blur(32px)'
                  }}
                >
                  <div className="framer-5tbvbw">
                    <div
                      style={{
                        borderRadius: 'inherit',
                        bottom: '0',
                        left: '0',
                        position: 'absolute',
                        right: '0',
                        top: '0'
                      }}
                    >
                      <img
                        alt=""
                        sizes="(max-width: 809px) calc(min(184px, 100vw) * 1.2011)"
                        src="../framerusercontent.com/images/fpslMqbcjoPLdTFuYphPG2I2vOg.jpg"
                        srcSet="https://framerusercontent.com/images/fpslMqbcjoPLdTFuYphPG2I2vOg.jpeg?scale-down-to=512 512w, https://framerusercontent.com/images/fpslMqbcjoPLdTFuYphPG2I2vOg.jpeg 775w"
                        style={{
                          borderRadius: 'inherit',
                          display: 'block',
                          height: '100%',
                          imageRendering: 'auto',
                          objectFit: 'cover',
                          objectPosition: 'center',
                          width: '100%'
                        }}
                      />
                    </div>
                  </div>
                  <div className="framer-1r8l7tp-container">
                    <video
                      loop
                      muted
                      playsInline
                      src="https://framerusercontent.com/assets/s4A7cFo8tD8qcICWbyw1zBIkv0A.mp4"
                      style={{
                        backgroundColor: 'rgba(0, 0, 0, 0)',
                        borderRadius: '0px',
                        cursor: 'auto',
                        display: 'block',
                        height: '100%',
                        objectFit: 'cover',
                        objectPosition: '50% 50%',
                        width: '100%'
                      }}
                    />
                  </div>
                </div>
                <div className="framer-1axnin4">
                  <div
                    className="framer-btp5e4"
                    style={{
                      backgroundColor: 'rgba(255, 255, 255, 0.1)',
                      borderBottomLeftRadius: '12px',
                      borderBottomRightRadius: '12px',
                      borderTopLeftRadius: '12px',
                      borderTopRightRadius: '12px'
                    }}
                  >
                    <div
                      className="framer-1o5m695"
                      style={{
                        borderBottomLeftRadius: '8px',
                        borderBottomRightRadius: '8px',
                        borderTopLeftRadius: '8px',
                        borderTopRightRadius: '8px'
                      }}
                    >
                      <div
                        style={{
                          borderRadius: 'inherit',
                          bottom: '0',
                          left: '0',
                          position: 'absolute',
                          right: '0',
                          top: '0'
                        }}
                      >
                        <img
                          alt=""
                          sizes="40px"
                          src="../framerusercontent.com/images/eRNoJZEYtKnUYJm9TmytZYiwiVg.png"
                          style={{
                            borderRadius: 'inherit',
                            display: 'block',
                            height: '100%',
                            imageRendering: 'auto',
                            objectFit: 'cover',
                            objectPosition: 'center',
                            width: '100%'
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <div className="ssr-variant hidden-175z5sy hidden-vh577v">
            <div
              background="%5bobject%20Object%5d.html"
              className="framer-1k8ags0"
              name="Design/stuff"
            >
              <div className="framer-17i38db">
                <div className="ssr-variant hidden-u21snj">
                  <div
                    className="framer-18w54sa"
                    name="Txt"
                    style={{
                      opacity: '0.001',
                      transform: 'perspective(1200px) translateX(0px) translateY(24px) scale(1) rotate(0deg) translateZ(0)'
                    }}
                  >
                    <div
                      className="framer-15xca2u"
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexShrink: '0',
                        justifyContent: 'center',
                        outline: 'none',
                        transform: 'none'
                      }}
                    >
                      <p
                        className="framer-text"
                        style={{
                          '--font-selector': 'Q1VTVE9NO0NhbGFmaWEgUmVndWxhcg==',
                          '--framer-font-family': '"Calafia Regular", "Calafia Regular Placeholder", sans-serif',
                          '--framer-font-size': '56px',
                          '--framer-text-alignment': 'center',
                          '--framer-text-color': 'rgb(255, 255, 255)'
                        }}
                      >
                        Digital Maker.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="ssr-variant hidden-72rtr7">
                  <div
                    className="framer-18w54sa"
                    name="Txt"
                    style={{
                      opacity: '0.001',
                      transform: 'perspective(1200px) translateX(0px) translateY(24px) scale(1) rotate(0deg) translateZ(0)'
                    }}
                  >
                    <div
                      className="framer-15xca2u"
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexShrink: '0',
                        justifyContent: 'center',
                        outline: 'none',
                        transform: 'none'
                      }}
                    >
                      <p
                        className="framer-text"
                        style={{
                          '--font-selector': 'Q1VTVE9NO0NhbGFmaWEgUmVndWxhcg==',
                          '--framer-font-family': '"Calafia Regular", "Calafia Regular Placeholder", sans-serif',
                          '--framer-font-size': '80px',
                          '--framer-text-alignment': 'center',
                          '--framer-text-color': 'rgb(255, 255, 255)'
                        }}
                      >
                        Digital Maker.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="framer-17prd44"
                  name="Tags"
                >
                  <div className="ssr-variant hidden-u21snj">
                    <div
                      className="framer-1y9rdds"
                      style={{
                        opacity: '0.001',
                        transform: 'perspective(1200px) translateX(0px) translateY(8px) scale(1) rotate(0deg) translateZ(0)'
                      }}
                    >
                      <div
                        className="framer-1kczd49"
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          flexShrink: '0',
                          justifyContent: 'flex-start',
                          outline: 'none',
                          transform: 'none'
                        }}
                      >
                        <p
                          className="framer-text"
                          style={{
                            '--font-selector': 'R0Y7RmlndHJlZS01MDA=',
                            '--framer-font-family': '"Figtree", "Figtree Placeholder", sans-serif',
                            '--framer-font-size': '13px',
                            '--framer-font-weight': '500',
                            '--framer-text-alignment': 'center',
                            '--framer-text-color': 'rgb(255, 255, 255)'
                          }}
                        >
                          UI
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="ssr-variant hidden-72rtr7">
                    <div
                      className="framer-1y9rdds"
                      style={{
                        opacity: '0.001',
                        transform: 'perspective(1200px) translateX(0px) translateY(8px) scale(1) rotate(0deg) translateZ(0)'
                      }}
                    >
                      <div
                        className="framer-1kczd49"
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          flexShrink: '0',
                          justifyContent: 'flex-start',
                          outline: 'none',
                          transform: 'none'
                        }}
                      >
                        <p
                          className="framer-text"
                          style={{
                            '--font-selector': 'R0Y7RmlndHJlZS01MDA=',
                            '--framer-font-family': '"Figtree", "Figtree Placeholder", sans-serif',
                            '--framer-font-weight': '500',
                            '--framer-text-alignment': 'center',
                            '--framer-text-color': 'rgb(255, 255, 255)'
                          }}
                        >
                          UI
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="ssr-variant hidden-u21snj">
                    <div
                      className="framer-12qciol"
                      style={{
                        opacity: '0.001',
                        transform: 'perspective(1200px) translateX(0px) translateY(8px) scale(1) rotate(0deg) translateZ(0)'
                      }}
                    >
                      <div
                        className="framer-4ff2qz"
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          flexShrink: '0',
                          justifyContent: 'flex-start',
                          outline: 'none',
                          transform: 'none'
                        }}
                      >
                        <p
                          className="framer-text"
                          style={{
                            '--font-selector': 'R0Y7RmlndHJlZS01MDA=',
                            '--framer-font-family': '"Figtree", "Figtree Placeholder", sans-serif',
                            '--framer-font-size': '13px',
                            '--framer-font-weight': '500',
                            '--framer-text-alignment': 'center',
                            '--framer-text-color': 'rgb(255, 255, 255)'
                          }}
                        >
                          UX
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="ssr-variant hidden-72rtr7">
                    <div
                      className="framer-12qciol"
                      style={{
                        opacity: '0.001',
                        transform: 'perspective(1200px) translateX(0px) translateY(8px) scale(1) rotate(0deg) translateZ(0)'
                      }}
                    >
                      <div
                        className="framer-4ff2qz"
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          flexShrink: '0',
                          justifyContent: 'flex-start',
                          outline: 'none',
                          transform: 'none'
                        }}
                      >
                        <p
                          className="framer-text"
                          style={{
                            '--font-selector': 'R0Y7RmlndHJlZS01MDA=',
                            '--framer-font-family': '"Figtree", "Figtree Placeholder", sans-serif',
                            '--framer-font-weight': '500',
                            '--framer-text-alignment': 'center',
                            '--framer-text-color': 'rgb(255, 255, 255)'
                          }}
                        >
                          UX
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="ssr-variant hidden-u21snj">
                    <div
                      className="framer-5dcweh"
                      style={{
                        opacity: '0.001',
                        transform: 'perspective(1200px) translateX(0px) translateY(8px) scale(1) rotate(0deg) translateZ(0)'
                      }}
                    >
                      <div
                        className="framer-1n7ivqd"
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          flexShrink: '0',
                          justifyContent: 'flex-start',
                          outline: 'none',
                          transform: 'none'
                        }}
                      >
                        <p
                          className="framer-text"
                          style={{
                            '--font-selector': 'R0Y7RmlndHJlZS01MDA=',
                            '--framer-font-family': '"Figtree", "Figtree Placeholder", sans-serif',
                            '--framer-font-size': '13px',
                            '--framer-font-weight': '500',
                            '--framer-text-alignment': 'center',
                            '--framer-text-color': 'rgb(255, 255, 255)'
                          }}
                        >
                          3D
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="ssr-variant hidden-72rtr7">
                    <div
                      className="framer-5dcweh"
                      style={{
                        opacity: '0.001',
                        transform: 'perspective(1200px) translateX(0px) translateY(8px) scale(1) rotate(0deg) translateZ(0)'
                      }}
                    >
                      <div
                        className="framer-1n7ivqd"
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          flexShrink: '0',
                          justifyContent: 'flex-start',
                          outline: 'none',
                          transform: 'none'
                        }}
                      >
                        <p
                          className="framer-text"
                          style={{
                            '--font-selector': 'R0Y7RmlndHJlZS01MDA=',
                            '--framer-font-family': '"Figtree", "Figtree Placeholder", sans-serif',
                            '--framer-font-weight': '500',
                            '--framer-text-alignment': 'center',
                            '--framer-text-color': 'rgb(255, 255, 255)'
                          }}
                        >
                          3D
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="ssr-variant hidden-u21snj">
                    <div
                      className="framer-1upumfo"
                      style={{
                        opacity: '0.001',
                        transform: 'perspective(1200px) translateX(0px) translateY(8px) scale(1) rotate(0deg) translateZ(0)'
                      }}
                    >
                      <div
                        className="framer-ulz2ve"
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          flexShrink: '0',
                          justifyContent: 'flex-start',
                          outline: 'none',
                          transform: 'none'
                        }}
                      >
                        <p
                          className="framer-text"
                          style={{
                            '--font-selector': 'R0Y7RmlndHJlZS01MDA=',
                            '--framer-font-family': '"Figtree", "Figtree Placeholder", sans-serif',
                            '--framer-font-size': '13px',
                            '--framer-font-weight': '500',
                            '--framer-text-alignment': 'center',
                            '--framer-text-color': 'rgb(255, 255, 255)'
                          }}
                        >
                          Branding
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="ssr-variant hidden-72rtr7">
                    <div
                      className="framer-1upumfo"
                      style={{
                        opacity: '0.001',
                        transform: 'perspective(1200px) translateX(0px) translateY(8px) scale(1) rotate(0deg) translateZ(0)'
                      }}
                    >
                      <div
                        className="framer-ulz2ve"
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          flexShrink: '0',
                          justifyContent: 'flex-start',
                          outline: 'none',
                          transform: 'none'
                        }}
                      >
                        <p
                          className="framer-text"
                          style={{
                            '--font-selector': 'R0Y7RmlndHJlZS01MDA=',
                            '--framer-font-family': '"Figtree", "Figtree Placeholder", sans-serif',
                            '--framer-font-weight': '500',
                            '--framer-text-alignment': 'center',
                            '--framer-text-color': 'rgb(255, 255, 255)'
                          }}
                        >
                          Branding
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="ssr-variant hidden-u21snj">
                    <div
                      className="framer-98gja7"
                      style={{
                        opacity: '0.001',
                        transform: 'perspective(1200px) translateX(0px) translateY(8px) scale(1) rotate(0deg) translateZ(0)'
                      }}
                    >
                      <div
                        className="framer-19c29w"
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          flexShrink: '0',
                          justifyContent: 'flex-start',
                          outline: 'none',
                          transform: 'none'
                        }}
                      >
                        <p
                          className="framer-text"
                          style={{
                            '--font-selector': 'R0Y7RmlndHJlZS01MDA=',
                            '--framer-font-family': '"Figtree", "Figtree Placeholder", sans-serif',
                            '--framer-font-size': '13px',
                            '--framer-font-weight': '500',
                            '--framer-text-alignment': 'center',
                            '--framer-text-color': 'rgb(255, 255, 255)'
                          }}
                        >
                          Design Systems
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="ssr-variant hidden-72rtr7">
                    <div
                      className="framer-98gja7"
                      style={{
                        opacity: '0.001',
                        transform: 'perspective(1200px) translateX(0px) translateY(8px) scale(1) rotate(0deg) translateZ(0)'
                      }}
                    >
                      <div
                        className="framer-19c29w"
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          flexShrink: '0',
                          justifyContent: 'flex-start',
                          outline: 'none',
                          transform: 'none'
                        }}
                      >
                        <p
                          className="framer-text"
                          style={{
                            '--font-selector': 'R0Y7RmlndHJlZS01MDA=',
                            '--framer-font-family': '"Figtree", "Figtree Placeholder", sans-serif',
                            '--framer-font-weight': '500',
                            '--framer-text-alignment': 'center',
                            '--framer-text-color': 'rgb(255, 255, 255)'
                          }}
                        >
                          Design Systems
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="framer-sod12k">
                <div className="ssr-variant hidden-u21snj">
                  <div
                    className="framer-jtgwc6-container"
                    style={{
                      opacity: '0.001',
                      transform: 'perspective(1200px) translateX(0px) translateY(-16px) scale(1) rotate(0deg) translateZ(0)'
                    }}
                  >
                    <div
                      style={{
                        display: 'contents'
                      }}
                    >
                      <a
                        className="framer-ic4vj framer-1trk8dq framer-v-1trk8dq framer-1b4d561"
                        href=""
                        tabIndex="0"
                      >
                        <div
                          className="framer-1mfksa2"
                          style={{
                            '--extracted-r6o4lv': 'rgba(255, 255, 255, 0.4)',
                            '--framer-link-text-color': 'rgb(0, 153, 255)',
                            '--framer-link-text-decoration': 'underline',
                            '--framer-paragraph-spacing': '0px',
                            display: 'flex',
                            flexDirection: 'column',
                            flexShrink: '0',
                            justifyContent: 'flex-start',
                            outline: 'none',
                            transform: 'rotate(270deg) translateZ(0)'
                          }}
                        >
                          <p
                            className="framer-text"
                            style={{
                              '--font-selector': 'R0Y7SW50ZXItNjAw',
                              '--framer-font-family': '"Inter", "Inter Placeholder", sans-serif',
                              '--framer-font-size': '20px',
                              '--framer-font-weight': '600',
                              '--framer-text-alignment': 'center',
                              '--framer-text-color': 'var(--extracted-r6o4lv, rgba(255, 255, 255, 0.4))'
                            }}
                          >
                            {`<-`}
                          </p>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="ssr-variant hidden-72rtr7">
                  <div
                    className="framer-jtgwc6-container"
                    style={{
                      opacity: '0.001',
                      transform: 'perspective(1200px) translateX(0px) translateY(-16px) scale(1) rotate(0deg) translateZ(0)'
                    }}
                  >
                    <div
                      style={{
                        display: 'contents'
                      }}
                    >
                      <a
                        className="framer-ic4vj framer-1trk8dq framer-v-1trk8dq framer-1b4d561"
                        href=""
                        tabIndex="0"
                      >
                        <div
                          className="framer-1mfksa2"
                          style={{
                            '--extracted-r6o4lv': 'rgba(255, 255, 255, 0.4)',
                            '--framer-link-text-color': 'rgb(0, 153, 255)',
                            '--framer-link-text-decoration': 'underline',
                            '--framer-paragraph-spacing': '0px',
                            display: 'flex',
                            flexDirection: 'column',
                            flexShrink: '0',
                            justifyContent: 'flex-start',
                            outline: 'none',
                            transform: 'rotate(270deg) translateZ(0)'
                          }}
                        >
                          <p
                            className="framer-text"
                            style={{
                              '--font-selector': 'R0Y7SW50ZXItNjAw',
                              '--framer-font-family': '"Inter", "Inter Placeholder", sans-serif',
                              '--framer-font-size': '20px',
                              '--framer-font-weight': '600',
                              '--framer-text-alignment': 'center',
                              '--framer-text-color': 'var(--extracted-r6o4lv, rgba(255, 255, 255, 0.4))'
                            }}
                          >
                            {`<-`}
                          </p>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="ssr-variant hidden-u21snj hidden-vh577v hidden-72rtr7">
            <div
              background="%5bobject%20Object%5d.html"
              className="framer-1k8ags0"
              name="Design/stuff"
            >
              <div className="framer-17i38db">
                <div
                  className="framer-18w54sa"
                  name="Txt"
                  style={{
                    opacity: '0.001',
                    transform: 'perspective(1200px) translateX(0px) translateY(24px) scale(1) rotate(0deg) translateZ(0)'
                  }}
                >
                  <div
                    className="framer-15xca2u"
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      flexShrink: '0',
                      justifyContent: 'center',
                      outline: 'none',
                      transform: 'none'
                    }}
                  >
                    <p
                      className="framer-text"
                      style={{
                        '--font-selector': 'Q1VTVE9NO0NhbGFmaWEgUmVndWxhcg==',
                        '--framer-font-family': '"Calafia Regular", "Calafia Regular Placeholder", sans-serif',
                        '--framer-font-size': '48px',
                        '--framer-text-alignment': 'center',
                        '--framer-text-color': 'rgb(255, 255, 255)'
                      }}
                    >
                      Digital Maker.
                    </p>
                  </div>
                </div>
                <div
                  className="framer-17prd44"
                  name="Tags"
                >
                  <div
                    className="framer-1y9rdds"
                    style={{
                      opacity: '0.001',
                      transform: 'perspective(1200px) translateX(0px) translateY(8px) scale(1) rotate(0deg) translateZ(0)'
                    }}
                  >
                    <div
                      className="framer-1kczd49"
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexShrink: '0',
                        justifyContent: 'flex-start',
                        outline: 'none',
                        transform: 'none'
                      }}
                    >
                      <p
                        className="framer-text"
                        style={{
                          '--font-selector': 'R0Y7RmlndHJlZS01MDA=',
                          '--framer-font-family': '"Figtree", "Figtree Placeholder", sans-serif',
                          '--framer-font-size': '13px',
                          '--framer-font-weight': '500',
                          '--framer-text-alignment': 'center',
                          '--framer-text-color': 'rgb(255, 255, 255)'
                        }}
                      >
                        UI
                      </p>
                    </div>
                  </div>
                  <div
                    className="framer-12qciol"
                    style={{
                      opacity: '0.001',
                      transform: 'perspective(1200px) translateX(0px) translateY(8px) scale(1) rotate(0deg) translateZ(0)'
                    }}
                  >
                    <div
                      className="framer-4ff2qz"
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexShrink: '0',
                        justifyContent: 'flex-start',
                        outline: 'none',
                        transform: 'none'
                      }}
                    >
                      <p
                        className="framer-text"
                        style={{
                          '--font-selector': 'R0Y7RmlndHJlZS01MDA=',
                          '--framer-font-family': '"Figtree", "Figtree Placeholder", sans-serif',
                          '--framer-font-size': '13px',
                          '--framer-font-weight': '500',
                          '--framer-text-alignment': 'center',
                          '--framer-text-color': 'rgb(255, 255, 255)'
                        }}
                      >
                        UX
                      </p>
                    </div>
                  </div>
                  <div
                    className="framer-5dcweh"
                    style={{
                      opacity: '0.001',
                      transform: 'perspective(1200px) translateX(0px) translateY(8px) scale(1) rotate(0deg) translateZ(0)'
                    }}
                  >
                    <div
                      className="framer-1n7ivqd"
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexShrink: '0',
                        justifyContent: 'flex-start',
                        outline: 'none',
                        transform: 'none'
                      }}
                    >
                      <p
                        className="framer-text"
                        style={{
                          '--font-selector': 'R0Y7RmlndHJlZS01MDA=',
                          '--framer-font-family': '"Figtree", "Figtree Placeholder", sans-serif',
                          '--framer-font-size': '13px',
                          '--framer-font-weight': '500',
                          '--framer-text-alignment': 'center',
                          '--framer-text-color': 'rgb(255, 255, 255)'
                        }}
                      >
                        3D
                      </p>
                    </div>
                  </div>
                  <div
                    className="framer-1upumfo"
                    style={{
                      opacity: '0.001',
                      transform: 'perspective(1200px) translateX(0px) translateY(8px) scale(1) rotate(0deg) translateZ(0)'
                    }}
                  >
                    <div
                      className="framer-ulz2ve"
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexShrink: '0',
                        justifyContent: 'flex-start',
                        outline: 'none',
                        transform: 'none'
                      }}
                    >
                      <p
                        className="framer-text"
                        style={{
                          '--font-selector': 'R0Y7RmlndHJlZS01MDA=',
                          '--framer-font-family': '"Figtree", "Figtree Placeholder", sans-serif',
                          '--framer-font-size': '13px',
                          '--framer-font-weight': '500',
                          '--framer-text-alignment': 'center',
                          '--framer-text-color': 'rgb(255, 255, 255)'
                        }}
                      >
                        Branding
                      </p>
                    </div>
                  </div>
                  <div
                    className="framer-98gja7"
                    style={{
                      opacity: '0.001',
                      transform: 'perspective(1200px) translateX(0px) translateY(8px) scale(1) rotate(0deg) translateZ(0)'
                    }}
                  >
                    <div
                      className="framer-19c29w"
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexShrink: '0',
                        justifyContent: 'flex-start',
                        outline: 'none',
                        transform: 'none'
                      }}
                    >
                      <p
                        className="framer-text"
                        style={{
                          '--font-selector': 'R0Y7RmlndHJlZS01MDA=',
                          '--framer-font-family': '"Figtree", "Figtree Placeholder", sans-serif',
                          '--framer-font-size': '13px',
                          '--framer-font-weight': '500',
                          '--framer-text-alignment': 'center',
                          '--framer-text-color': 'rgb(255, 255, 255)'
                        }}
                      >
                        Design Systems
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="framer-sod12k">
                <div
                  className="framer-jtgwc6-container"
                  style={{
                    opacity: '0.001',
                    transform: 'perspective(1200px) translateX(0px) translateY(-16px) scale(1) rotate(0deg) translateZ(0)'
                  }}
                >
                  <div
                    style={{
                      display: 'contents'
                    }}
                  >
                    <a
                      className="framer-ic4vj framer-1trk8dq framer-v-1trk8dq framer-1b4d561"
                      href=""
                      tabIndex="0"
                    >
                      <div
                        className="framer-1mfksa2"
                        style={{
                          '--extracted-r6o4lv': 'rgba(255, 255, 255, 0.4)',
                          '--framer-link-text-color': 'rgb(0, 153, 255)',
                          '--framer-link-text-decoration': 'underline',
                          '--framer-paragraph-spacing': '0px',
                          display: 'flex',
                          flexDirection: 'column',
                          flexShrink: '0',
                          justifyContent: 'flex-start',
                          outline: 'none',
                          transform: 'rotate(270deg) translateZ(0)'
                        }}
                      >
                        <p
                          className="framer-text"
                          style={{
                            '--font-selector': 'R0Y7SW50ZXItNjAw',
                            '--framer-font-family': '"Inter", "Inter Placeholder", sans-serif',
                            '--framer-font-size': '20px',
                            '--framer-font-weight': '600',
                            '--framer-text-alignment': 'center',
                            '--framer-text-color': 'var(--extracted-r6o4lv, rgba(255, 255, 255, 0.4))'
                          }}
                        >
                          {`<-`}
                        </p>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="ssr-variant hidden-175z5sy hidden-u21snj hidden-72rtr7">
            <div
              background="%5bobject%20Object%5d.html"
              className="framer-1k8ags0"
              name="Design/stuff"
            >
              <div className="framer-17i38db">
                <div
                  className="framer-18w54sa"
                  name="Txt"
                  style={{
                    opacity: '0.001',
                    transform: 'perspective(1200px) translateX(0px) translateY(24px) scale(1) rotate(0deg) translateZ(0)'
                  }}
                >
                  <div
                    className="framer-15xca2u"
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      flexShrink: '0',
                      justifyContent: 'center',
                      outline: 'none',
                      transform: 'none'
                    }}
                  >
                    <p
                      className="framer-text"
                      style={{
                        '--font-selector': 'Q1VTVE9NO0NhbGFmaWEgUmVndWxhcg==',
                        '--framer-font-family': '"Calafia Regular", "Calafia Regular Placeholder", sans-serif',
                        '--framer-font-size': '56px',
                        '--framer-text-alignment': 'center',
                        '--framer-text-color': 'rgb(255, 255, 255)'
                      }}
                    >
                      Digital Maker.
                    </p>
                  </div>
                </div>
                <div
                  className="framer-17prd44"
                  name="Tags"
                >
                  <div
                    className="framer-1y9rdds"
                    style={{
                      opacity: '0.001',
                      transform: 'perspective(1200px) translateX(0px) translateY(8px) scale(1) rotate(0deg) translateZ(0)'
                    }}
                  >
                    <div
                      className="framer-1kczd49"
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexShrink: '0',
                        justifyContent: 'flex-start',
                        outline: 'none',
                        transform: 'none'
                      }}
                    >
                      <p
                        className="framer-text"
                        style={{
                          '--font-selector': 'R0Y7RmlndHJlZS01MDA=',
                          '--framer-font-family': '"Figtree", "Figtree Placeholder", sans-serif',
                          '--framer-font-size': '13px',
                          '--framer-font-weight': '500',
                          '--framer-text-alignment': 'center',
                          '--framer-text-color': 'rgb(255, 255, 255)'
                        }}
                      >
                        UI
                      </p>
                    </div>
                  </div>
                  <div
                    className="framer-12qciol"
                    style={{
                      opacity: '0.001',
                      transform: 'perspective(1200px) translateX(0px) translateY(8px) scale(1) rotate(0deg) translateZ(0)'
                    }}
                  >
                    <div
                      className="framer-4ff2qz"
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexShrink: '0',
                        justifyContent: 'flex-start',
                        outline: 'none',
                        transform: 'none'
                      }}
                    >
                      <p
                        className="framer-text"
                        style={{
                          '--font-selector': 'R0Y7RmlndHJlZS01MDA=',
                          '--framer-font-family': '"Figtree", "Figtree Placeholder", sans-serif',
                          '--framer-font-size': '13px',
                          '--framer-font-weight': '500',
                          '--framer-text-alignment': 'center',
                          '--framer-text-color': 'rgb(255, 255, 255)'
                        }}
                      >
                        UX
                      </p>
                    </div>
                  </div>
                  <div
                    className="framer-5dcweh"
                    style={{
                      opacity: '0.001',
                      transform: 'perspective(1200px) translateX(0px) translateY(8px) scale(1) rotate(0deg) translateZ(0)'
                    }}
                  >
                    <div
                      className="framer-1n7ivqd"
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexShrink: '0',
                        justifyContent: 'flex-start',
                        outline: 'none',
                        transform: 'none'
                      }}
                    >
                      <p
                        className="framer-text"
                        style={{
                          '--font-selector': 'R0Y7RmlndHJlZS01MDA=',
                          '--framer-font-family': '"Figtree", "Figtree Placeholder", sans-serif',
                          '--framer-font-size': '13px',
                          '--framer-font-weight': '500',
                          '--framer-text-alignment': 'center',
                          '--framer-text-color': 'rgb(255, 255, 255)'
                        }}
                      >
                        3D
                      </p>
                    </div>
                  </div>
                  <div
                    className="framer-1upumfo"
                    style={{
                      opacity: '0.001',
                      transform: 'perspective(1200px) translateX(0px) translateY(8px) scale(1) rotate(0deg) translateZ(0)'
                    }}
                  >
                    <div
                      className="framer-ulz2ve"
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexShrink: '0',
                        justifyContent: 'flex-start',
                        outline: 'none',
                        transform: 'none'
                      }}
                    >
                      <p
                        className="framer-text"
                        style={{
                          '--font-selector': 'R0Y7RmlndHJlZS01MDA=',
                          '--framer-font-family': '"Figtree", "Figtree Placeholder", sans-serif',
                          '--framer-font-size': '13px',
                          '--framer-font-weight': '500',
                          '--framer-text-alignment': 'center',
                          '--framer-text-color': 'rgb(255, 255, 255)'
                        }}
                      >
                        Branding
                      </p>
                    </div>
                  </div>
                  <div
                    className="framer-98gja7"
                    style={{
                      opacity: '0.001',
                      transform: 'perspective(1200px) translateX(0px) translateY(8px) scale(1) rotate(0deg) translateZ(0)'
                    }}
                  >
                    <div
                      className="framer-19c29w"
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexShrink: '0',
                        justifyContent: 'flex-start',
                        outline: 'none',
                        transform: 'none'
                      }}
                    >
                      <p
                        className="framer-text"
                        style={{
                          '--font-selector': 'R0Y7RmlndHJlZS01MDA=',
                          '--framer-font-family': '"Figtree", "Figtree Placeholder", sans-serif',
                          '--framer-font-size': '13px',
                          '--framer-font-weight': '500',
                          '--framer-text-alignment': 'center',
                          '--framer-text-color': 'rgb(255, 255, 255)'
                        }}
                      >
                        Design Systems
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="framer-sod12k">
                <div
                  className="framer-jtgwc6-container"
                  style={{
                    opacity: '0.001',
                    transform: 'perspective(1200px) translateX(0px) translateY(-16px) scale(1) rotate(0deg) translateZ(0)'
                  }}
                >
                  <div
                    style={{
                      display: 'contents'
                    }}
                  >
                    <a
                      className="framer-ic4vj framer-1trk8dq framer-v-1trk8dq framer-1b4d561"
                      href=""
                      tabIndex="0"
                    >
                      <div
                        className="framer-1mfksa2"
                        style={{
                          '--extracted-r6o4lv': 'rgba(255, 255, 255, 0.4)',
                          '--framer-link-text-color': 'rgb(0, 153, 255)',
                          '--framer-link-text-decoration': 'underline',
                          '--framer-paragraph-spacing': '0px',
                          display: 'flex',
                          flexDirection: 'column',
                          flexShrink: '0',
                          justifyContent: 'flex-start',
                          outline: 'none',
                          transform: 'rotate(270deg) translateZ(0)'
                        }}
                      >
                        <p
                          className="framer-text"
                          style={{
                            '--font-selector': 'R0Y7SW50ZXItNjAw',
                            '--framer-font-family': '"Inter", "Inter Placeholder", sans-serif',
                            '--framer-font-size': '20px',
                            '--framer-font-weight': '600',
                            '--framer-text-alignment': 'center',
                            '--framer-text-color': 'var(--extracted-r6o4lv, rgba(255, 255, 255, 0.4))'
                          }}
                        >
                          {`<-`}
                        </p>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="ssr-variant hidden-175z5sy hidden-u21snj hidden-vh577v">
          <div
            className="framer-12ze0cw-container"
            style={{
              opacity: '0.001',
              transform: 'perspective(1200px) translateX(32px) translateY(0px) scale(1) rotate(0deg) translateZ(0)'
            }}
          >
            <a
              className="framer-uviIJ framer-1a2vike framer-v-1a2vike framer-1wv0y5h"
              href="Petrosen.html"
              style={{
                '--border-bottom-width': '1px',
                '--border-color': 'rgba(255, 255, 255, 0.1)',
                '--border-left-width': '1px',
                '--border-right-width': '1px',
                '--border-style': 'solid',
                '--border-top-width': '1px',
                backgroundColor: 'rgb(0, 0, 0)',
                borderBottomLeftRadius: '24px',
                borderBottomRightRadius: '24px',
                borderTopLeftRadius: '24px',
                borderTopRightRadius: '24px',
                height: '100%',
                width: '100%'
              }}
              tabIndex="0"
            >
              <div className="framer-19kjldf-container">
                <div
                  style={{
                    height: '100%',
                    overflow: 'hidden',
                    position: 'relative',
                    width: '100%'
                  }}
                >
                  <div
                    style={{
                      background: 'url(\'../framerusercontent.com/images/rR6HYXBrMmX4cRpXfXUOvpvpB0.png\')',
                      backgroundRepeat: 'repeat',
                      backgroundSize: '256px 256px',
                      height: '400%',
                      inset: '-200%',
                      opacity: '0.02',
                      position: 'absolute',
                      width: '400%'
                    }}
                   />
                </div>
              </div>
              <div
                className="framer-3tnfe8"
                style={{
                  WebkitFilter: 'blur(32px)',
                  backgroundColor: 'rgb(0, 0, 0)',
                  filter: 'blur(32px)'
                }}
              >
                <div className="framer-5tbvbw">
                  <div
                    style={{
                      borderRadius: 'inherit',
                      bottom: '0',
                      left: '0',
                      position: 'absolute',
                      right: '0',
                      top: '0'
                    }}
                  >
                    <img
                      alt=""
                      sizes="(min-width: 1049px) and (max-width: 1792px) calc(min(184px, 100vw) * 1.2011), (min-width: 810px) and (max-width: 1048px) calc(min(184px, 100vw) * 1.2011), (min-width: 1793px) calc(min(184px, 100vw) * 1.2011), (max-width: 809px) calc(min(184px, 100vw) * 1.2011)"
                      src="/assets/images/2kuMaQNBoMyO9bjnzl5h06810.png"
                      srcSet="/assets/images/2kuMaQNBoMyO9bjnzl5h06810.png?scale-down-to=512 512w, /assets/images/2kuMaQNBoMyO9bjnzl5h06810.png?scale-down-to=1024 1024w, /assets/images/2kuMaQNBoMyO9bjnzl5h06810.png 1728w"
                      style={{
                        borderRadius: 'inherit',
                        display: 'block',
                        height: '100%',
                        imageRendering: 'auto',
                        objectFit: 'cover',
                        objectPosition: 'center',
                        width: '100%'
                      }}
                    />
                  </div>
                </div>
                <div className="framer-1r8l7tp-container">
                  <video
                    loop
                    muted
                    playsInline
                    src="/assets/videos/video-petrosen-pubc.mp4"
                    style={{
                      backgroundColor: 'rgba(0, 0, 0, 0)',
                      borderRadius: '0px',
                      cursor: 'auto',
                      display: 'block',
                      height: '100%',
                      objectFit: 'cover',
                      objectPosition: '50% 50%',
                      width: '100%'
                    }}
                  />
                </div>
              </div>
              <div className="framer-1axnin4">
                <div
                  className="framer-btp5e4"
                  style={{
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    borderBottomLeftRadius: '12px',
                    borderBottomRightRadius: '12px',
                    borderTopLeftRadius: '12px',
                    borderTopRightRadius: '12px'
                  }}
                >
                  <div
                    className="framer-1o5m695"
                    style={{
                      borderBottomLeftRadius: '8px',
                      borderBottomRightRadius: '8px',
                      borderTopLeftRadius: '8px',
                      borderTopRightRadius: '8px'
                    }}
                  >
                    <div
                      style={{
                        borderRadius: 'inherit',
                        bottom: '0',
                        left: '0',
                        position: 'absolute',
                        right: '0',
                        top: '0'
                      }}
                    >
                      <img
                        alt=""
                        sizes="40px"
                        src="../framerusercontent.com/images/oSk7DB3g0Pdoj1jooATypMoZ3w.png"
                        style={{
                          borderRadius: 'inherit',
                          display: 'block',
                          height: '100%',
                          imageRendering: 'auto',
                          objectFit: 'cover',
                          objectPosition: 'center',
                          width: '100%'
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div className="ssr-variant hidden-u21snj hidden-vh577v hidden-72rtr7">
          <div
            className="framer-12ze0cw-container"
            style={{
              opacity: '0.001',
              transform: 'perspective(1200px) translateX(32px) translateY(0px) scale(1) rotate(0deg) translateZ(0)'
            }}
          >
            <a
              className="framer-uviIJ framer-1a2vike framer-v-1a2vike framer-1wv0y5h"
              href="Petrosen.html"
              style={{
                '--border-bottom-width': '1px',
                '--border-color': 'rgba(255, 255, 255, 0.1)',
                '--border-left-width': '1px',
                '--border-right-width': '1px',
                '--border-style': 'solid',
                '--border-top-width': '1px',
                backgroundColor: 'rgb(0, 0, 0)',
                borderBottomLeftRadius: '24px',
                borderBottomRightRadius: '24px',
                borderTopLeftRadius: '24px',
                borderTopRightRadius: '24px',
                height: '100%',
                width: '100%'
              }}
              tabIndex="0"
            >
              <div className="framer-19kjldf-container">
                <div
                  style={{
                    height: '100%',
                    overflow: 'hidden',
                    position: 'relative',
                    width: '100%'
                  }}
                >
                  <div
                    style={{
                      background: 'url(\'../framerusercontent.com/images/rR6HYXBrMmX4cRpXfXUOvpvpB0.png\')',
                      backgroundRepeat: 'repeat',
                      backgroundSize: '256px 256px',
                      height: '400%',
                      inset: '-200%',
                      opacity: '0.02',
                      position: 'absolute',
                      width: '400%'
                    }}
                   />
                </div>
              </div>
              <div
                className="framer-3tnfe8"
                style={{
                  WebkitFilter: 'blur(32px)',
                  backgroundColor: 'rgb(0, 0, 0)',
                  filter: 'blur(32px)'
                }}
              >
                <div className="framer-5tbvbw">
                  <div
                    style={{
                      borderRadius: 'inherit',
                      bottom: '0',
                      left: '0',
                      position: 'absolute',
                      right: '0',
                      top: '0'
                    }}
                  >
                    <img
                      alt=""
                      sizes="(min-width: 1049px) and (max-width: 1792px) calc(min(184px, 100vw) * 1.2011), (min-width: 810px) and (max-width: 1048px) calc(min(184px, 100vw) * 1.2011), (min-width: 1793px) calc(min(184px, 100vw) * 1.2011), (max-width: 809px) calc(min(184px, 100vw) * 1.2011)"
                      src="/assets/images/2kuMaQNBoMyO9bjnzl5h06810.png"
                      srcSet="/assets/images/2kuMaQNBoMyO9bjnzl5h06810.png?scale-down-to=512 512w, /assets/images/2kuMaQNBoMyO9bjnzl5h06810.png?scale-down-to=1024 1024w, /assets/images/2kuMaQNBoMyO9bjnzl5h06810.png 1728w"
                      style={{
                        borderRadius: 'inherit',
                        display: 'block',
                        height: '100%',
                        imageRendering: 'auto',
                        objectFit: 'cover',
                        objectPosition: 'center',
                        width: '100%'
                      }}
                    />
                  </div>
                </div>
                <div className="framer-1r8l7tp-container">
                  <video
                    loop
                    muted
                    playsInline
                    src="/assets/videos/video-petrosen-pubc.mp4"
                    style={{
                      backgroundColor: 'rgba(0, 0, 0, 0)',
                      borderRadius: '0px',
                      cursor: 'auto',
                      display: 'block',
                      height: '100%',
                      objectFit: 'cover',
                      objectPosition: '50% 50%',
                      width: '100%'
                    }}
                  />
                </div>
              </div>
              <div className="framer-1axnin4">
                <div
                  className="framer-btp5e4"
                  style={{
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    borderBottomLeftRadius: '12px',
                    borderBottomRightRadius: '12px',
                    borderTopLeftRadius: '12px',
                    borderTopRightRadius: '12px'
                  }}
                >
                  <div
                    className="framer-1o5m695"
                    style={{
                      borderBottomLeftRadius: '8px',
                      borderBottomRightRadius: '8px',
                      borderTopLeftRadius: '8px',
                      borderTopRightRadius: '8px'
                    }}
                  >
                    <div
                      style={{
                        borderRadius: 'inherit',
                        bottom: '0',
                        left: '0',
                        position: 'absolute',
                        right: '0',
                        top: '0'
                      }}
                    >
                      <img
                        alt=""
                        sizes="40px"
                        src="../framerusercontent.com/images/oSk7DB3g0Pdoj1jooATypMoZ3w.png"
                        style={{
                          borderRadius: 'inherit',
                          display: 'block',
                          height: '100%',
                          imageRendering: 'auto',
                          objectFit: 'cover',
                          objectPosition: 'center',
                          width: '100%'
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div className="ssr-variant hidden-175z5sy hidden-vh577v hidden-72rtr7">
          <div
            className="framer-12ze0cw-container"
            style={{
              opacity: '0.001',
              transform: 'perspective(1200px) translateX(32px) translateY(0px) scale(1) rotate(0deg) translateZ(0)'
            }}
          >
            <a
              className="framer-uviIJ framer-1a2vike framer-v-1a2vike framer-1wv0y5h"
              href="Petrosen.html"
              style={{
                '--border-bottom-width': '1px',
                '--border-color': 'rgba(255, 255, 255, 0.1)',
                '--border-left-width': '1px',
                '--border-right-width': '1px',
                '--border-style': 'solid',
                '--border-top-width': '1px',
                backgroundColor: 'rgb(0, 0, 0)',
                borderBottomLeftRadius: '24px',
                borderBottomRightRadius: '24px',
                borderTopLeftRadius: '24px',
                borderTopRightRadius: '24px',
                height: '100%',
                width: '100%'
              }}
              tabIndex="0"
            >
              <div className="framer-19kjldf-container">
                <div
                  style={{
                    height: '100%',
                    overflow: 'hidden',
                    position: 'relative',
                    width: '100%'
                  }}
                >
                  <div
                    style={{
                      background: 'url(\'../framerusercontent.com/images/rR6HYXBrMmX4cRpXfXUOvpvpB0.png\')',
                      backgroundRepeat: 'repeat',
                      backgroundSize: '256px 256px',
                      height: '400%',
                      inset: '-200%',
                      opacity: '0.02',
                      position: 'absolute',
                      width: '400%'
                    }}
                   />
                </div>
              </div>
              <div
                className="framer-3tnfe8"
                style={{
                  WebkitFilter: 'blur(32px)',
                  backgroundColor: 'rgb(0, 0, 0)',
                  filter: 'blur(32px)'
                }}
              >
                <div className="framer-5tbvbw">
                  <div
                    style={{
                      borderRadius: 'inherit',
                      bottom: '0',
                      left: '0',
                      position: 'absolute',
                      right: '0',
                      top: '0'
                    }}
                  >
                    <img
                      alt=""
                      sizes="(min-width: 1049px) and (max-width: 1792px) calc(min(184px, 100vw) * 1.2011), (min-width: 810px) and (max-width: 1048px) calc(min(184px, 100vw) * 1.2011), (min-width: 1793px) calc(min(184px, 100vw) * 1.2011), (max-width: 809px) calc(min(184px, 100vw) * 1.2011)"
                      src="/assets/images/2kuMaQNBoMyO9bjnzl5h06810.png"
                      srcSet="/assets/images/2kuMaQNBoMyO9bjnzl5h06810.png?scale-down-to=512 512w, /assets/images/2kuMaQNBoMyO9bjnzl5h06810.png?scale-down-to=1024 1024w, /assets/images/2kuMaQNBoMyO9bjnzl5h06810.png 1728w"
                      style={{
                        borderRadius: 'inherit',
                        display: 'block',
                        height: '100%',
                        imageRendering: 'auto',
                        objectFit: 'cover',
                        objectPosition: 'center',
                        width: '100%'
                      }}
                    />
                  </div>
                </div>
                <div className="framer-1r8l7tp-container">
                  <video
                    loop
                    muted
                    playsInline
                    src="/assets/videos/video-petrosen-pubc.mp4"
                    style={{
                      backgroundColor: 'rgba(0, 0, 0, 0)',
                      borderRadius: '0px',
                      cursor: 'auto',
                      display: 'block',
                      height: '100%',
                      objectFit: 'cover',
                      objectPosition: '50% 50%',
                      width: '100%'
                    }}
                  />
                </div>
              </div>
              <div className="framer-1axnin4">
                <div
                  className="framer-btp5e4"
                  style={{
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    borderBottomLeftRadius: '12px',
                    borderBottomRightRadius: '12px',
                    borderTopLeftRadius: '12px',
                    borderTopRightRadius: '12px'
                  }}
                >
                  <div
                    className="framer-1o5m695"
                    style={{
                      borderBottomLeftRadius: '8px',
                      borderBottomRightRadius: '8px',
                      borderTopLeftRadius: '8px',
                      borderTopRightRadius: '8px'
                    }}
                  >
                    <div
                      style={{
                        borderRadius: 'inherit',
                        bottom: '0',
                        left: '0',
                        position: 'absolute',
                        right: '0',
                        top: '0'
                      }}
                    >
                      <img
                        alt=""
                        sizes="40px"
                        src="../framerusercontent.com/images/oSk7DB3g0Pdoj1jooATypMoZ3w.png"
                        style={{
                          borderRadius: 'inherit',
                          display: 'block',
                          height: '100%',
                          imageRendering: 'auto',
                          objectFit: 'cover',
                          objectPosition: 'center',
                          width: '100%'
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div className="ssr-variant hidden-175z5sy hidden-u21snj hidden-72rtr7">
          <div
            className="framer-12ze0cw-container"
            style={{
              opacity: '0.001',
              transform: 'perspective(1200px) translateX(32px) translateY(0px) scale(1) rotate(0deg) translateZ(0)'
            }}
          >
            <a
              className="framer-uviIJ framer-1a2vike framer-v-4kcy1m framer-1wv0y5h"
              style={{
                '--border-bottom-width': '1px',
                '--border-color': 'rgba(255, 255, 255, 0.1)',
                '--border-left-width': '1px',
                '--border-right-width': '1px',
                '--border-style': 'solid',
                '--border-top-width': '1px',
                backgroundColor: 'rgb(0, 0, 0)',
                borderBottomLeftRadius: '24px',
                borderBottomRightRadius: '24px',
                borderTopLeftRadius: '24px',
                borderTopRightRadius: '24px',
                height: '100%',
                width: '100%'
              }}
              tabIndex="0"
            >
              <div className="framer-19kjldf-container">
                <div
                  style={{
                    height: '100%',
                    overflow: 'hidden',
                    position: 'relative',
                    width: '100%'
                  }}
                >
                  <div
                    style={{
                      background: 'url(\'../framerusercontent.com/images/rR6HYXBrMmX4cRpXfXUOvpvpB0.png\')',
                      backgroundRepeat: 'repeat',
                      backgroundSize: '256px 256px',
                      height: '400%',
                      inset: '-200%',
                      opacity: '0.02',
                      position: 'absolute',
                      width: '400%'
                    }}
                   />
                </div>
              </div>
              <div
                className="framer-3tnfe8"
                style={{
                  WebkitFilter: 'blur(32px)',
                  backgroundColor: 'rgb(0, 0, 0)',
                  filter: 'blur(32px)'
                }}
              >
                <div className="framer-5tbvbw">
                  <div
                    style={{
                      borderRadius: 'inherit',
                      bottom: '0',
                      left: '0',
                      position: 'absolute',
                      right: '0',
                      top: '0'
                    }}
                  >
                    <img
                      alt=""
                      sizes="(min-width: 1049px) and (max-width: 1792px) calc(min(184px, 100vw) * 1.2011), (min-width: 810px) and (max-width: 1048px) calc(min(184px, 100vw) * 1.2011), (min-width: 1793px) calc(min(184px, 100vw) * 1.2011), (max-width: 809px) calc(min(184px, 100vw) * 1.2011)"
                      src="/assets/images/2kuMaQNBoMyO9bjnzl5h06810.png"
                      srcSet="/assets/images/2kuMaQNBoMyO9bjnzl5h06810.png?scale-down-to=512 512w, /assets/images/2kuMaQNBoMyO9bjnzl5h06810.png?scale-down-to=1024 1024w, /assets/images/2kuMaQNBoMyO9bjnzl5h06810.png 1728w"
                      style={{
                        borderRadius: 'inherit',
                        display: 'block',
                        height: '100%',
                        imageRendering: 'auto',
                        objectFit: 'cover',
                        objectPosition: 'center',
                        width: '100%'
                      }}
                    />
                  </div>
                </div>
                <div className="framer-1r8l7tp-container">
                  <video
                    loop
                    muted
                    playsInline
                    src="https://framerusercontent.com/assets/4Hd7vZK97jgICboXho4mPCgyI.mp4"
                    style={{
                      backgroundColor: 'rgba(0, 0, 0, 0)',
                      borderRadius: '0px',
                      cursor: 'auto',
                      display: 'block',
                      height: '100%',
                      objectFit: 'cover',
                      objectPosition: '50% 50%',
                      width: '100%'
                    }}
                  />
                </div>
              </div>
              <div className="framer-1axnin4">
                <div
                  className="framer-btp5e4"
                  style={{
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    borderBottomLeftRadius: '12px',
                    borderBottomRightRadius: '12px',
                    borderTopLeftRadius: '12px',
                    borderTopRightRadius: '12px'
                  }}
                >
                  <div
                    className="framer-1o5m695"
                    style={{
                      borderBottomLeftRadius: '8px',
                      borderBottomRightRadius: '8px',
                      borderTopLeftRadius: '8px',
                      borderTopRightRadius: '8px'
                    }}
                  >
                    <div
                      style={{
                        borderRadius: 'inherit',
                        bottom: '0',
                        left: '0',
                        position: 'absolute',
                        right: '0',
                        top: '0'
                      }}
                    >
                      <img
                        alt=""
                        sizes="40px"
                        src="../framerusercontent.com/images/oSk7DB3g0Pdoj1jooATypMoZ3w.png"
                        style={{
                          borderRadius: 'inherit',
                          display: 'block',
                          height: '100%',
                          imageRendering: 'auto',
                          objectFit: 'cover',
                          objectPosition: 'center',
                          width: '100%'
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
      <div
        className="framer-1kc325z"
        name="Bottom"
      >
        <div className="ssr-variant hidden-175z5sy hidden-u21snj hidden-vh577v">
          <div
            className="framer-s8gtgu-container"
            style={{
              opacity: '0.001',
              transform: 'perspective(1200px) translateX(-32px) translateY(0px) scale(1) rotate(0deg) translateZ(0)'
            }}
          >
            <a
              className="framer-uviIJ framer-1a2vike framer-v-1a2vike framer-1wv0y5h"
              href="liveguide.html"
              style={{
                '--border-bottom-width': '1px',
                '--border-color': 'rgba(255, 255, 255, 0.1)',
                '--border-left-width': '1px',
                '--border-right-width': '1px',
                '--border-style': 'solid',
                '--border-top-width': '1px',
                backgroundColor: 'rgb(0, 0, 0)',
                borderBottomLeftRadius: '24px',
                borderBottomRightRadius: '24px',
                borderTopLeftRadius: '24px',
                borderTopRightRadius: '24px',
                height: '100%',
                width: '100%'
              }}
              tabIndex="0"
            >
              <div className="framer-19kjldf-container">
                <div
                  style={{
                    height: '100%',
                    overflow: 'hidden',
                    position: 'relative',
                    width: '100%'
                  }}
                >
                  <div
                    style={{
                      background: 'url(\'../framerusercontent.com/images/rR6HYXBrMmX4cRpXfXUOvpvpB0.png\')',
                      backgroundRepeat: 'repeat',
                      backgroundSize: '256px 256px',
                      height: '400%',
                      inset: '-200%',
                      opacity: '0.02',
                      position: 'absolute',
                      width: '400%'
                    }}
                   />
                </div>
              </div>
              <div
                className="framer-3tnfe8"
                style={{
                  WebkitFilter: 'blur(32px)',
                  backgroundColor: 'rgb(0, 0, 0)',
                  filter: 'blur(32px)'
                }}
              >
                <div className="framer-5tbvbw">
                  <div
                    style={{
                      borderRadius: 'inherit',
                      bottom: '0',
                      left: '0',
                      position: 'absolute',
                      right: '0',
                      top: '0'
                    }}
                  >
                    <img
                      alt=""
                      sizes="(min-width: 1049px) and (max-width: 1792px) calc(min(184px, 100vw) * 1.2011), (min-width: 810px) and (max-width: 1048px) calc(min(184px, 100vw) * 1.2011), (min-width: 1793px) calc(min(184px, 100vw) * 1.2011), (max-width: 809px) calc(min(184px, 100vw) * 1.2011)"
                      src="/assets/images/NnyupiJAnSbLo4RPxtulaoTpI.png"
                      srcSet="/assets/images/NnyupiJAnSbLo4RPxtulaoTpI.png?scale-down-to=512 512w, /assets/images/NnyupiJAnSbLo4RPxtulaoTpI.png?scale-down-to=1024 1024w, /assets/images/NnyupiJAnSbLo4RPxtulaoTpI.png 1920w"
                      style={{
                        borderRadius: 'inherit',
                        display: 'block',
                        height: '100%',
                        imageRendering: 'auto',
                        objectFit: 'cover',
                        objectPosition: 'center',
                        width: '100%'
                      }}
                    />
                  </div>
                </div>
                <div className="framer-1r8l7tp-container">
                  <video
                    loop
                    muted
                    playsInline
                    src="/assets/videos/video-proassur.mp4"
                    style={{
                      backgroundColor: 'rgba(0, 0, 0, 0)',
                      borderRadius: '0px',
                      cursor: 'auto',
                      display: 'block',
                      height: '100%',
                      objectFit: 'cover',
                      objectPosition: '50% 50%',
                      width: '100%'
                    }}
                  />
                </div>
              </div>
              <div className="framer-1axnin4">
                <div
                  className="framer-btp5e4"
                  style={{
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    borderBottomLeftRadius: '12px',
                    borderBottomRightRadius: '12px',
                    borderTopLeftRadius: '12px',
                    borderTopRightRadius: '12px'
                  }}
                >
                  <div
                    className="framer-1o5m695"
                    style={{
                      borderBottomLeftRadius: '8px',
                      borderBottomRightRadius: '8px',
                      borderTopLeftRadius: '8px',
                      borderTopRightRadius: '8px'
                    }}
                  >
                    <div
                      style={{
                        borderRadius: 'inherit',
                        bottom: '0',
                        left: '0',
                        position: 'absolute',
                        right: '0',
                        top: '0'
                      }}
                    >
                      <img
                        alt=""
                        sizes="40px"
                        src="../framerusercontent.com/images/uZAG17IsLWWFAVaPMU8iPPyFzt4.png"
                        style={{
                          borderRadius: 'inherit',
                          display: 'block',
                          height: '100%',
                          imageRendering: 'auto',
                          objectFit: 'cover',
                          objectPosition: 'center',
                          width: '100%'
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div className="ssr-variant hidden-u21snj hidden-vh577v hidden-72rtr7">
          <div
            className="framer-s8gtgu-container"
            style={{
              opacity: '0.001',
              transform: 'perspective(1200px) translateX(-32px) translateY(0px) scale(1) rotate(0deg) translateZ(0)'
            }}
          >
            <a
              className="framer-uviIJ framer-1a2vike framer-v-1a2vike framer-1wv0y5h"
              href="liveguide.html"
              style={{
                '--border-bottom-width': '1px',
                '--border-color': 'rgba(255, 255, 255, 0.1)',
                '--border-left-width': '1px',
                '--border-right-width': '1px',
                '--border-style': 'solid',
                '--border-top-width': '1px',
                backgroundColor: 'rgb(0, 0, 0)',
                borderBottomLeftRadius: '24px',
                borderBottomRightRadius: '24px',
                borderTopLeftRadius: '24px',
                borderTopRightRadius: '24px',
                height: '100%',
                width: '100%'
              }}
              tabIndex="0"
            >
              <div className="framer-19kjldf-container">
                <div
                  style={{
                    height: '100%',
                    overflow: 'hidden',
                    position: 'relative',
                    width: '100%'
                  }}
                >
                  <div
                    style={{
                      background: 'url(\'../framerusercontent.com/images/rR6HYXBrMmX4cRpXfXUOvpvpB0.png\')',
                      backgroundRepeat: 'repeat',
                      backgroundSize: '256px 256px',
                      height: '400%',
                      inset: '-200%',
                      opacity: '0.02',
                      position: 'absolute',
                      width: '400%'
                    }}
                   />
                </div>
              </div>
              <div
                className="framer-3tnfe8"
                style={{
                  WebkitFilter: 'blur(32px)',
                  backgroundColor: 'rgb(0, 0, 0)',
                  filter: 'blur(32px)'
                }}
              >
                <div className="framer-5tbvbw">
                  <div
                    style={{
                      borderRadius: 'inherit',
                      bottom: '0',
                      left: '0',
                      position: 'absolute',
                      right: '0',
                      top: '0'
                    }}
                  >
                    <img
                      alt=""
                      sizes="(min-width: 1049px) and (max-width: 1792px) calc(min(184px, 100vw) * 1.2011), (min-width: 810px) and (max-width: 1048px) calc(min(184px, 100vw) * 1.2011), (min-width: 1793px) calc(min(184px, 100vw) * 1.2011), (max-width: 809px) calc(min(184px, 100vw) * 1.2011)"
                      src="/assets/images/NnyupiJAnSbLo4RPxtulaoTpI.png"
                      srcSet="/assets/images/NnyupiJAnSbLo4RPxtulaoTpI.png?scale-down-to=512 512w, /assets/images/NnyupiJAnSbLo4RPxtulaoTpI.png?scale-down-to=1024 1024w, /assets/images/NnyupiJAnSbLo4RPxtulaoTpI.png 1920w"
                      style={{
                        borderRadius: 'inherit',
                        display: 'block',
                        height: '100%',
                        imageRendering: 'auto',
                        objectFit: 'cover',
                        objectPosition: 'center',
                        width: '100%'
                      }}
                    />
                  </div>
                </div>
                <div className="framer-1r8l7tp-container">
                  <video
                    loop
                    muted
                    playsInline
                    src="/assets/videos/video-proassur.mp4"
                    style={{
                      backgroundColor: 'rgba(0, 0, 0, 0)',
                      borderRadius: '0px',
                      cursor: 'auto',
                      display: 'block',
                      height: '100%',
                      objectFit: 'cover',
                      objectPosition: '50% 50%',
                      width: '100%'
                    }}
                  />
                </div>
              </div>
              <div className="framer-1axnin4">
                <div
                  className="framer-btp5e4"
                  style={{
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    borderBottomLeftRadius: '12px',
                    borderBottomRightRadius: '12px',
                    borderTopLeftRadius: '12px',
                    borderTopRightRadius: '12px'
                  }}
                >
                  <div
                    className="framer-1o5m695"
                    style={{
                      borderBottomLeftRadius: '8px',
                      borderBottomRightRadius: '8px',
                      borderTopLeftRadius: '8px',
                      borderTopRightRadius: '8px'
                    }}
                  >
                    <div
                      style={{
                        borderRadius: 'inherit',
                        bottom: '0',
                        left: '0',
                        position: 'absolute',
                        right: '0',
                        top: '0'
                      }}
                    >
                      <img
                        alt=""
                        sizes="40px"
                        src="../framerusercontent.com/images/uZAG17IsLWWFAVaPMU8iPPyFzt4.png"
                        style={{
                          borderRadius: 'inherit',
                          display: 'block',
                          height: '100%',
                          imageRendering: 'auto',
                          objectFit: 'cover',
                          objectPosition: 'center',
                          width: '100%'
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div className="ssr-variant hidden-175z5sy hidden-vh577v hidden-72rtr7">
          <div
            className="framer-s8gtgu-container"
            style={{
              opacity: '0.001',
              transform: 'perspective(1200px) translateX(-32px) translateY(0px) scale(1) rotate(0deg) translateZ(0)'
            }}
          >
            <a
              className="framer-uviIJ framer-1a2vike framer-v-1a2vike framer-1wv0y5h"
              href="liveguide.html"
              style={{
                '--border-bottom-width': '1px',
                '--border-color': 'rgba(255, 255, 255, 0.1)',
                '--border-left-width': '1px',
                '--border-right-width': '1px',
                '--border-style': 'solid',
                '--border-top-width': '1px',
                backgroundColor: 'rgb(0, 0, 0)',
                borderBottomLeftRadius: '24px',
                borderBottomRightRadius: '24px',
                borderTopLeftRadius: '24px',
                borderTopRightRadius: '24px',
                height: '100%',
                width: '100%'
              }}
              tabIndex="0"
            >
              <div className="framer-19kjldf-container">
                <div
                  style={{
                    height: '100%',
                    overflow: 'hidden',
                    position: 'relative',
                    width: '100%'
                  }}
                >
                  <div
                    style={{
                      background: 'url(\'../framerusercontent.com/images/rR6HYXBrMmX4cRpXfXUOvpvpB0.png\')',
                      backgroundRepeat: 'repeat',
                      backgroundSize: '256px 256px',
                      height: '400%',
                      inset: '-200%',
                      opacity: '0.02',
                      position: 'absolute',
                      width: '400%'
                    }}
                   />
                </div>
              </div>
              <div
                className="framer-3tnfe8"
                style={{
                  WebkitFilter: 'blur(32px)',
                  backgroundColor: 'rgb(0, 0, 0)',
                  filter: 'blur(32px)'
                }}
              >
                <div className="framer-5tbvbw">
                  <div
                    style={{
                      borderRadius: 'inherit',
                      bottom: '0',
                      left: '0',
                      position: 'absolute',
                      right: '0',
                      top: '0'
                    }}
                  >
                    <img
                      alt=""
                      sizes="(min-width: 1049px) and (max-width: 1792px) calc(min(184px, 100vw) * 1.2011), (min-width: 810px) and (max-width: 1048px) calc(min(184px, 100vw) * 1.2011), (min-width: 1793px) calc(min(184px, 100vw) * 1.2011), (max-width: 809px) calc(min(184px, 100vw) * 1.2011)"
                      src="/assets/images/NnyupiJAnSbLo4RPxtulaoTpI.png"
                      srcSet="/assets/images/NnyupiJAnSbLo4RPxtulaoTpI.png?scale-down-to=512 512w, /assets/images/NnyupiJAnSbLo4RPxtulaoTpI.png?scale-down-to=1024 1024w, /assets/images/NnyupiJAnSbLo4RPxtulaoTpI.png 1920w"
                      style={{
                        borderRadius: 'inherit',
                        display: 'block',
                        height: '100%',
                        imageRendering: 'auto',
                        objectFit: 'cover',
                        objectPosition: 'center',
                        width: '100%'
                      }}
                    />
                  </div>
                </div>
                <div className="framer-1r8l7tp-container">
                  <video
                    loop
                    muted
                    playsInline
                    src="/assets/videos/video-proassur.mp4"
                    style={{
                      backgroundColor: 'rgba(0, 0, 0, 0)',
                      borderRadius: '0px',
                      cursor: 'auto',
                      display: 'block',
                      height: '100%',
                      objectFit: 'cover',
                      objectPosition: '50% 50%',
                      width: '100%'
                    }}
                  />
                </div>
              </div>
              <div className="framer-1axnin4">
                <div
                  className="framer-btp5e4"
                  style={{
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    borderBottomLeftRadius: '12px',
                    borderBottomRightRadius: '12px',
                    borderTopLeftRadius: '12px',
                    borderTopRightRadius: '12px'
                  }}
                >
                  <div
                    className="framer-1o5m695"
                    style={{
                      borderBottomLeftRadius: '8px',
                      borderBottomRightRadius: '8px',
                      borderTopLeftRadius: '8px',
                      borderTopRightRadius: '8px'
                    }}
                  >
                    <div
                      style={{
                        borderRadius: 'inherit',
                        bottom: '0',
                        left: '0',
                        position: 'absolute',
                        right: '0',
                        top: '0'
                      }}
                    >
                      <img
                        alt=""
                        sizes="40px"
                        src="../framerusercontent.com/images/uZAG17IsLWWFAVaPMU8iPPyFzt4.png"
                        style={{
                          borderRadius: 'inherit',
                          display: 'block',
                          height: '100%',
                          imageRendering: 'auto',
                          objectFit: 'cover',
                          objectPosition: 'center',
                          width: '100%'
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div className="ssr-variant hidden-175z5sy hidden-u21snj hidden-72rtr7">
          <div
            className="framer-s8gtgu-container"
            style={{
              opacity: '0.001',
              transform: 'perspective(1200px) translateX(-32px) translateY(0px) scale(1) rotate(0deg) translateZ(0)'
            }}
          >
            <a
              className="framer-uviIJ framer-1a2vike framer-v-4kcy1m framer-1wv0y5h"
              style={{
                '--border-bottom-width': '1px',
                '--border-color': 'rgba(255, 255, 255, 0.1)',
                '--border-left-width': '1px',
                '--border-right-width': '1px',
                '--border-style': 'solid',
                '--border-top-width': '1px',
                backgroundColor: 'rgb(0, 0, 0)',
                borderBottomLeftRadius: '24px',
                borderBottomRightRadius: '24px',
                borderTopLeftRadius: '24px',
                borderTopRightRadius: '24px',
                height: '100%',
                width: '100%'
              }}
              tabIndex="0"
            >
              <div className="framer-19kjldf-container">
                <div
                  style={{
                    height: '100%',
                    overflow: 'hidden',
                    position: 'relative',
                    width: '100%'
                  }}
                >
                  <div
                    style={{
                      background: 'url(\'../framerusercontent.com/images/rR6HYXBrMmX4cRpXfXUOvpvpB0.png\')',
                      backgroundRepeat: 'repeat',
                      backgroundSize: '256px 256px',
                      height: '400%',
                      inset: '-200%',
                      opacity: '0.02',
                      position: 'absolute',
                      width: '400%'
                    }}
                   />
                </div>
              </div>
              <div
                className="framer-3tnfe8"
                style={{
                  WebkitFilter: 'blur(32px)',
                  backgroundColor: 'rgb(0, 0, 0)',
                  filter: 'blur(32px)'
                }}
              >
                <div className="framer-5tbvbw">
                  <div
                    style={{
                      borderRadius: 'inherit',
                      bottom: '0',
                      left: '0',
                      position: 'absolute',
                      right: '0',
                      top: '0'
                    }}
                  >
                    <img
                      alt=""
                      sizes="(min-width: 1049px) and (max-width: 1792px) calc(min(184px, 100vw) * 1.2011), (min-width: 810px) and (max-width: 1048px) calc(min(184px, 100vw) * 1.2011), (min-width: 1793px) calc(min(184px, 100vw) * 1.2011), (max-width: 809px) calc(min(184px, 100vw) * 1.2011)"
                      src="/assets/images/NnyupiJAnSbLo4RPxtulaoTpI.png"
                      srcSet="/assets/images/NnyupiJAnSbLo4RPxtulaoTpI.png?scale-down-to=512 512w, /assets/images/NnyupiJAnSbLo4RPxtulaoTpI.png?scale-down-to=1024 1024w, /assets/images/NnyupiJAnSbLo4RPxtulaoTpI.png 1920w"
                      style={{
                        borderRadius: 'inherit',
                        display: 'block',
                        height: '100%',
                        imageRendering: 'auto',
                        objectFit: 'cover',
                        objectPosition: 'center',
                        width: '100%'
                      }}
                    />
                  </div>
                </div>
                <div className="framer-1r8l7tp-container">
                  <video
                    loop
                    muted
                    playsInline
                    src="https://framerusercontent.com/assets/3gWoHSnlHmRC8Mg5NpZoGAiXaE.mp4"
                    style={{
                      backgroundColor: 'rgba(0, 0, 0, 0)',
                      borderRadius: '0px',
                      cursor: 'auto',
                      display: 'block',
                      height: '100%',
                      objectFit: 'cover',
                      objectPosition: '50% 50%',
                      width: '100%'
                    }}
                  />
                </div>
              </div>
              <div className="framer-1axnin4">
                <div
                  className="framer-btp5e4"
                  style={{
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    borderBottomLeftRadius: '12px',
                    borderBottomRightRadius: '12px',
                    borderTopLeftRadius: '12px',
                    borderTopRightRadius: '12px'
                  }}
                >
                  <div
                    className="framer-1o5m695"
                    style={{
                      borderBottomLeftRadius: '8px',
                      borderBottomRightRadius: '8px',
                      borderTopLeftRadius: '8px',
                      borderTopRightRadius: '8px'
                    }}
                  >
                    <div
                      style={{
                        borderRadius: 'inherit',
                        bottom: '0',
                        left: '0',
                        position: 'absolute',
                        right: '0',
                        top: '0'
                      }}
                    >
                      <img
                        alt=""
                        sizes="40px"
                        src="../framerusercontent.com/images/uZAG17IsLWWFAVaPMU8iPPyFzt4.png"
                        style={{
                          borderRadius: 'inherit',
                          display: 'block',
                          height: '100%',
                          imageRendering: 'auto',
                          objectFit: 'cover',
                          objectPosition: 'center',
                          width: '100%'
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div
          className="framer-k8ci44"
          name="Test"
        >
          <div className="ssr-variant hidden-175z5sy hidden-u21snj hidden-vh577v">
            <div
              className="framer-1mpc3ia-container"
              style={{
                opacity: '0.001',
                transform: 'perspective(1200px) translateX(0px) translateY(32px) scale(1) rotate(0deg) translateZ(0)'
              }}
            >
              <a
                className="framer-uviIJ framer-1a2vike framer-v-1a2vike framer-1wv0y5h"
                href="keyflow.html"
                style={{
                  '--border-bottom-width': '1px',
                  '--border-color': 'rgba(255, 255, 255, 0.1)',
                  '--border-left-width': '1px',
                  '--border-right-width': '1px',
                  '--border-style': 'solid',
                  '--border-top-width': '1px',
                  backgroundColor: 'rgb(0, 0, 0)',
                  borderBottomLeftRadius: '24px',
                  borderBottomRightRadius: '24px',
                  borderTopLeftRadius: '24px',
                  borderTopRightRadius: '24px',
                  height: '100%',
                  width: '100%'
                }}
                tabIndex="0"
              >
                <div className="framer-19kjldf-container">
                  <div
                    style={{
                      height: '100%',
                      overflow: 'hidden',
                      position: 'relative',
                      width: '100%'
                    }}
                  >
                    <div
                      style={{
                        background: 'url(\'../framerusercontent.com/images/rR6HYXBrMmX4cRpXfXUOvpvpB0.png\')',
                        backgroundRepeat: 'repeat',
                        backgroundSize: '256px 256px',
                        height: '400%',
                        inset: '-200%',
                        opacity: '0.02',
                        position: 'absolute',
                        width: '400%'
                      }}
                     />
                  </div>
                </div>
                <div
                  className="framer-3tnfe8"
                  style={{
                    WebkitFilter: 'blur(32px)',
                    backgroundColor: 'rgb(0, 0, 0)',
                    filter: 'blur(32px)'
                  }}
                >
                  <div className="framer-5tbvbw">
                    <div
                      style={{
                        borderRadius: 'inherit',
                        bottom: '0',
                        left: '0',
                        position: 'absolute',
                        right: '0',
                        top: '0'
                      }}
                    >
                      <img
                        alt=""
                        sizes="(min-width: 1049px) and (max-width: 1792px) calc(min(184px, 100vw) * 1.2011), (min-width: 810px) and (max-width: 1048px) calc(min(184px, 100vw) * 1.2011), (min-width: 1793px) calc(min(184px, 100vw) * 1.2011), (max-width: 809px) calc(min(184px, 100vw) * 1.2011)"
                        src="https://framerusercontent.com/images/yjgBnbsDiKAD1tgcYXLjwsWLKoc.webp"
                        srcSet="https://framerusercontent.com/images/yjgBnbsDiKAD1tgcYXLjwsWLKoc.webp?scale-down-to=512 512w, https://framerusercontent.com/images/yjgBnbsDiKAD1tgcYXLjwsWLKoc.webp?scale-down-to=1024 1024w, https://framerusercontent.com/images/yjgBnbsDiKAD1tgcYXLjwsWLKoc.webp 1080w"
                        style={{
                          borderRadius: 'inherit',
                          display: 'block',
                          height: '100%',
                          imageRendering: 'auto',
                          objectFit: 'cover',
                          objectPosition: 'center',
                          width: '100%'
                        }}
                      />
                    </div>
                  </div>
                  <div className="framer-1r8l7tp-container">
                    <video
                      loop
                      muted
                      playsInline
                      src="https://framerusercontent.com/assets/SbfwVCKqFgXs4RbLQy4oZXzipBc.mp4"
                      style={{
                        backgroundColor: 'rgba(0, 0, 0, 0)',
                        borderRadius: '0px',
                        cursor: 'auto',
                        display: 'block',
                        height: '100%',
                        objectFit: 'cover',
                        objectPosition: '50% 50%',
                        width: '100%'
                      }}
                    />
                  </div>
                </div>
                <div className="framer-1axnin4">
                  <div
                    className="framer-btp5e4"
                    style={{
                      backgroundColor: 'rgba(255, 255, 255, 0.1)',
                      borderBottomLeftRadius: '12px',
                      borderBottomRightRadius: '12px',
                      borderTopLeftRadius: '12px',
                      borderTopRightRadius: '12px'
                    }}
                  >
                    <div
                      className="framer-1o5m695"
                      style={{
                        borderBottomLeftRadius: '8px',
                        borderBottomRightRadius: '8px',
                        borderTopLeftRadius: '8px',
                        borderTopRightRadius: '8px'
                      }}
                    >
                      <div
                        style={{
                          borderRadius: 'inherit',
                          bottom: '0',
                          left: '0',
                          position: 'absolute',
                          right: '0',
                          top: '0'
                        }}
                      >
                        <img
                          alt=""
                          sizes="40px"
                          src="../framerusercontent.com/images/IQKJcYnQ1PNT4SFGDGeE2QIeo4.png"
                          style={{
                            borderRadius: 'inherit',
                            display: 'block',
                            height: '100%',
                            imageRendering: 'auto',
                            objectFit: 'cover',
                            objectPosition: 'center',
                            width: '100%'
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <div className="ssr-variant hidden-u21snj hidden-vh577v hidden-72rtr7">
            <div
              className="framer-1mpc3ia-container"
              style={{
                opacity: '0.001',
                transform: 'perspective(1200px) translateX(0px) translateY(32px) scale(1) rotate(0deg) translateZ(0)'
              }}
            >
              <a
                className="framer-uviIJ framer-1a2vike framer-v-1a2vike framer-1wv0y5h"
                href="keyflow.html"
                style={{
                  '--border-bottom-width': '1px',
                  '--border-color': 'rgba(255, 255, 255, 0.1)',
                  '--border-left-width': '1px',
                  '--border-right-width': '1px',
                  '--border-style': 'solid',
                  '--border-top-width': '1px',
                  backgroundColor: 'rgb(0, 0, 0)',
                  borderBottomLeftRadius: '24px',
                  borderBottomRightRadius: '24px',
                  borderTopLeftRadius: '24px',
                  borderTopRightRadius: '24px',
                  height: '100%',
                  width: '100%'
                }}
                tabIndex="0"
              >
                <div className="framer-19kjldf-container">
                  <div
                    style={{
                      height: '100%',
                      overflow: 'hidden',
                      position: 'relative',
                      width: '100%'
                    }}
                  >
                    <div
                      style={{
                        background: 'url(\'../framerusercontent.com/images/rR6HYXBrMmX4cRpXfXUOvpvpB0.png\')',
                        backgroundRepeat: 'repeat',
                        backgroundSize: '256px 256px',
                        height: '400%',
                        inset: '-200%',
                        opacity: '0.02',
                        position: 'absolute',
                        width: '400%'
                      }}
                     />
                  </div>
                </div>
                <div
                  className="framer-3tnfe8"
                  style={{
                    WebkitFilter: 'blur(32px)',
                    backgroundColor: 'rgb(0, 0, 0)',
                    filter: 'blur(32px)'
                  }}
                >
                  <div className="framer-5tbvbw">
                    <div
                      style={{
                        borderRadius: 'inherit',
                        bottom: '0',
                        left: '0',
                        position: 'absolute',
                        right: '0',
                        top: '0'
                      }}
                    >
                      <img
                        alt=""
                        sizes="(min-width: 1049px) and (max-width: 1792px) calc(min(184px, 100vw) * 1.2011), (min-width: 810px) and (max-width: 1048px) calc(min(184px, 100vw) * 1.2011), (min-width: 1793px) calc(min(184px, 100vw) * 1.2011), (max-width: 809px) calc(min(184px, 100vw) * 1.2011)"
                        src="https://framerusercontent.com/images/yjgBnbsDiKAD1tgcYXLjwsWLKoc.webp"
                        srcSet="https://framerusercontent.com/images/yjgBnbsDiKAD1tgcYXLjwsWLKoc.webp?scale-down-to=512 512w, https://framerusercontent.com/images/yjgBnbsDiKAD1tgcYXLjwsWLKoc.webp?scale-down-to=1024 1024w, https://framerusercontent.com/images/yjgBnbsDiKAD1tgcYXLjwsWLKoc.webp 1080w"
                        style={{
                          borderRadius: 'inherit',
                          display: 'block',
                          height: '100%',
                          imageRendering: 'auto',
                          objectFit: 'cover',
                          objectPosition: 'center',
                          width: '100%'
                        }}
                      />
                    </div>
                  </div>
                  <div className="framer-1r8l7tp-container">
                    <video
                      loop
                      muted
                      playsInline
                      src="https://framerusercontent.com/assets/SbfwVCKqFgXs4RbLQy4oZXzipBc.mp4"
                      style={{
                        backgroundColor: 'rgba(0, 0, 0, 0)',
                        borderRadius: '0px',
                        cursor: 'auto',
                        display: 'block',
                        height: '100%',
                        objectFit: 'cover',
                        objectPosition: '50% 50%',
                        width: '100%'
                      }}
                    />
                  </div>
                </div>
                <div className="framer-1axnin4">
                  <div
                    className="framer-btp5e4"
                    style={{
                      backgroundColor: 'rgba(255, 255, 255, 0.1)',
                      borderBottomLeftRadius: '12px',
                      borderBottomRightRadius: '12px',
                      borderTopLeftRadius: '12px',
                      borderTopRightRadius: '12px'
                    }}
                  >
                    <div
                      className="framer-1o5m695"
                      style={{
                        borderBottomLeftRadius: '8px',
                        borderBottomRightRadius: '8px',
                        borderTopLeftRadius: '8px',
                        borderTopRightRadius: '8px'
                      }}
                    >
                      <div
                        style={{
                          borderRadius: 'inherit',
                          bottom: '0',
                          left: '0',
                          position: 'absolute',
                          right: '0',
                          top: '0'
                        }}
                      >
                        <img
                          alt=""
                          sizes="40px"
                          src="../framerusercontent.com/images/IQKJcYnQ1PNT4SFGDGeE2QIeo4.png"
                          style={{
                            borderRadius: 'inherit',
                            display: 'block',
                            height: '100%',
                            imageRendering: 'auto',
                            objectFit: 'cover',
                            objectPosition: 'center',
                            width: '100%'
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <div className="ssr-variant hidden-175z5sy hidden-vh577v hidden-72rtr7">
            <div
              className="framer-1mpc3ia-container"
              style={{
                opacity: '0.001',
                transform: 'perspective(1200px) translateX(0px) translateY(32px) scale(1) rotate(0deg) translateZ(0)'
              }}
            >
              <a
                className="framer-uviIJ framer-1a2vike framer-v-1a2vike framer-1wv0y5h"
                href="keyflow.html"
                style={{
                  '--border-bottom-width': '1px',
                  '--border-color': 'rgba(255, 255, 255, 0.1)',
                  '--border-left-width': '1px',
                  '--border-right-width': '1px',
                  '--border-style': 'solid',
                  '--border-top-width': '1px',
                  backgroundColor: 'rgb(0, 0, 0)',
                  borderBottomLeftRadius: '24px',
                  borderBottomRightRadius: '24px',
                  borderTopLeftRadius: '24px',
                  borderTopRightRadius: '24px',
                  height: '100%',
                  width: '100%'
                }}
                tabIndex="0"
              >
                <div className="framer-19kjldf-container">
                  <div
                    style={{
                      height: '100%',
                      overflow: 'hidden',
                      position: 'relative',
                      width: '100%'
                    }}
                  >
                    <div
                      style={{
                        background: 'url(\'../framerusercontent.com/images/rR6HYXBrMmX4cRpXfXUOvpvpB0.png\')',
                        backgroundRepeat: 'repeat',
                        backgroundSize: '256px 256px',
                        height: '400%',
                        inset: '-200%',
                        opacity: '0.02',
                        position: 'absolute',
                        width: '400%'
                      }}
                     />
                  </div>
                </div>
                <div
                  className="framer-3tnfe8"
                  style={{
                    WebkitFilter: 'blur(32px)',
                    backgroundColor: 'rgb(0, 0, 0)',
                    filter: 'blur(32px)'
                  }}
                >
                  <div className="framer-5tbvbw">
                    <div
                      style={{
                        borderRadius: 'inherit',
                        bottom: '0',
                        left: '0',
                        position: 'absolute',
                        right: '0',
                        top: '0'
                      }}
                    >
                      <img
                        alt=""
                        sizes="(min-width: 1049px) and (max-width: 1792px) calc(min(184px, 100vw) * 1.2011), (min-width: 810px) and (max-width: 1048px) calc(min(184px, 100vw) * 1.2011), (min-width: 1793px) calc(min(184px, 100vw) * 1.2011), (max-width: 809px) calc(min(184px, 100vw) * 1.2011)"
                        src="https://framerusercontent.com/images/yjgBnbsDiKAD1tgcYXLjwsWLKoc.webp"
                        srcSet="https://framerusercontent.com/images/yjgBnbsDiKAD1tgcYXLjwsWLKoc.webp?scale-down-to=512 512w, https://framerusercontent.com/images/yjgBnbsDiKAD1tgcYXLjwsWLKoc.webp?scale-down-to=1024 1024w, https://framerusercontent.com/images/yjgBnbsDiKAD1tgcYXLjwsWLKoc.webp 1080w"
                        style={{
                          borderRadius: 'inherit',
                          display: 'block',
                          height: '100%',
                          imageRendering: 'auto',
                          objectFit: 'cover',
                          objectPosition: 'center',
                          width: '100%'
                        }}
                      />
                    </div>
                  </div>
                  <div className="framer-1r8l7tp-container">
                    <video
                      loop
                      muted
                      playsInline
                      src="https://framerusercontent.com/assets/SbfwVCKqFgXs4RbLQy4oZXzipBc.mp4"
                      style={{
                        backgroundColor: 'rgba(0, 0, 0, 0)',
                        borderRadius: '0px',
                        cursor: 'auto',
                        display: 'block',
                        height: '100%',
                        objectFit: 'cover',
                        objectPosition: '50% 50%',
                        width: '100%'
                      }}
                    />
                  </div>
                </div>
                <div className="framer-1axnin4">
                  <div
                    className="framer-btp5e4"
                    style={{
                      backgroundColor: 'rgba(255, 255, 255, 0.1)',
                      borderBottomLeftRadius: '12px',
                      borderBottomRightRadius: '12px',
                      borderTopLeftRadius: '12px',
                      borderTopRightRadius: '12px'
                    }}
                  >
                    <div
                      className="framer-1o5m695"
                      style={{
                        borderBottomLeftRadius: '8px',
                        borderBottomRightRadius: '8px',
                        borderTopLeftRadius: '8px',
                        borderTopRightRadius: '8px'
                      }}
                    >
                      <div
                        style={{
                          borderRadius: 'inherit',
                          bottom: '0',
                          left: '0',
                          position: 'absolute',
                          right: '0',
                          top: '0'
                        }}
                      >
                        <img
                          alt=""
                          sizes="40px"
                          src="../framerusercontent.com/images/IQKJcYnQ1PNT4SFGDGeE2QIeo4.png"
                          style={{
                            borderRadius: 'inherit',
                            display: 'block',
                            height: '100%',
                            imageRendering: 'auto',
                            objectFit: 'cover',
                            objectPosition: 'center',
                            width: '100%'
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <div className="ssr-variant hidden-175z5sy hidden-u21snj hidden-72rtr7">
            <div
              className="framer-1mpc3ia-container"
              style={{
                opacity: '0.001',
                transform: 'perspective(1200px) translateX(0px) translateY(32px) scale(1) rotate(0deg) translateZ(0)'
              }}
            >
              <a
                className="framer-uviIJ framer-1a2vike framer-v-4kcy1m framer-1wv0y5h"
                style={{
                  '--border-bottom-width': '1px',
                  '--border-color': 'rgba(255, 255, 255, 0.1)',
                  '--border-left-width': '1px',
                  '--border-right-width': '1px',
                  '--border-style': 'solid',
                  '--border-top-width': '1px',
                  backgroundColor: 'rgb(0, 0, 0)',
                  borderBottomLeftRadius: '24px',
                  borderBottomRightRadius: '24px',
                  borderTopLeftRadius: '24px',
                  borderTopRightRadius: '24px',
                  height: '100%',
                  width: '100%'
                }}
                tabIndex="0"
              >
                <div className="framer-19kjldf-container">
                  <div
                    style={{
                      height: '100%',
                      overflow: 'hidden',
                      position: 'relative',
                      width: '100%'
                    }}
                  >
                    <div
                      style={{
                        background: 'url(\'../framerusercontent.com/images/rR6HYXBrMmX4cRpXfXUOvpvpB0.png\')',
                        backgroundRepeat: 'repeat',
                        backgroundSize: '256px 256px',
                        height: '400%',
                        inset: '-200%',
                        opacity: '0.02',
                        position: 'absolute',
                        width: '400%'
                      }}
                     />
                  </div>
                </div>
                <div
                  className="framer-3tnfe8"
                  style={{
                    WebkitFilter: 'blur(32px)',
                    backgroundColor: 'rgb(0, 0, 0)',
                    filter: 'blur(32px)'
                  }}
                >
                  <div className="framer-5tbvbw">
                    <div
                      style={{
                        borderRadius: 'inherit',
                        bottom: '0',
                        left: '0',
                        position: 'absolute',
                        right: '0',
                        top: '0'
                      }}
                    >
                      <img
                        alt=""
                        sizes="(min-width: 1049px) and (max-width: 1792px) calc(min(184px, 100vw) * 1.2011), (min-width: 810px) and (max-width: 1048px) calc(min(184px, 100vw) * 1.2011), (min-width: 1793px) calc(min(184px, 100vw) * 1.2011), (max-width: 809px) calc(min(184px, 100vw) * 1.2011)"
                        src="https://framerusercontent.com/images/yjgBnbsDiKAD1tgcYXLjwsWLKoc.webp"
                        srcSet="https://framerusercontent.com/images/yjgBnbsDiKAD1tgcYXLjwsWLKoc.webp?scale-down-to=512 512w, https://framerusercontent.com/images/yjgBnbsDiKAD1tgcYXLjwsWLKoc.webp?scale-down-to=1024 1024w, https://framerusercontent.com/images/yjgBnbsDiKAD1tgcYXLjwsWLKoc.webp 1080w"
                        style={{
                          borderRadius: 'inherit',
                          display: 'block',
                          height: '100%',
                          imageRendering: 'auto',
                          objectFit: 'cover',
                          objectPosition: 'center',
                          width: '100%'
                        }}
                      />
                    </div>
                  </div>
                  <div className="framer-1r8l7tp-container">
                    <video
                      loop
                      muted
                      playsInline
                      src="https://framerusercontent.com/assets/QwCVk6TaHXs97YJS9X5jqDoFA.mp4"
                      style={{
                        backgroundColor: 'rgba(0, 0, 0, 0)',
                        borderRadius: '0px',
                        cursor: 'auto',
                        display: 'block',
                        height: '100%',
                        objectFit: 'cover',
                        objectPosition: '50% 50%',
                        width: '100%'
                      }}
                    />
                  </div>
                </div>
                <div className="framer-1axnin4">
                  <div
                    className="framer-btp5e4"
                    style={{
                      backgroundColor: 'rgba(255, 255, 255, 0.1)',
                      borderBottomLeftRadius: '12px',
                      borderBottomRightRadius: '12px',
                      borderTopLeftRadius: '12px',
                      borderTopRightRadius: '12px'
                    }}
                  >
                    <div
                      className="framer-1o5m695"
                      style={{
                        borderBottomLeftRadius: '8px',
                        borderBottomRightRadius: '8px',
                        borderTopLeftRadius: '8px',
                        borderTopRightRadius: '8px'
                      }}
                    >
                      <div
                        style={{
                          borderRadius: 'inherit',
                          bottom: '0',
                          left: '0',
                          position: 'absolute',
                          right: '0',
                          top: '0'
                        }}
                      >
                        <img
                          alt=""
                          sizes="40px"
                          src="../framerusercontent.com/images/IQKJcYnQ1PNT4SFGDGeE2QIeo4.png"
                          style={{
                            borderRadius: 'inherit',
                            display: 'block',
                            height: '100%',
                            imageRendering: 'auto',
                            objectFit: 'cover',
                            objectPosition: 'center',
                            width: '100%'
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <div className="ssr-variant hidden-175z5sy hidden-u21snj hidden-vh577v">
            <div
              className="framer-17i3ubv-container"
              style={{
                opacity: '0.001',
                transform: 'perspective(1200px) translateX(0px) translateY(32px) scale(1) rotate(0deg) translateZ(0)'
              }}
            >
              <a
                className="framer-uviIJ framer-1a2vike framer-v-1a2vike framer-1wv0y5h"
                href="wrapp.html"
                style={{
                  '--border-bottom-width': '1px',
                  '--border-color': 'rgba(255, 255, 255, 0.1)',
                  '--border-left-width': '1px',
                  '--border-right-width': '1px',
                  '--border-style': 'solid',
                  '--border-top-width': '1px',
                  backgroundColor: 'rgb(0, 0, 0)',
                  borderBottomLeftRadius: '24px',
                  borderBottomRightRadius: '24px',
                  borderTopLeftRadius: '24px',
                  borderTopRightRadius: '24px',
                  height: '100%',
                  width: '100%'
                }}
                tabIndex="0"
              >
                <div className="framer-19kjldf-container">
                  <div
                    style={{
                      height: '100%',
                      overflow: 'hidden',
                      position: 'relative',
                      width: '100%'
                    }}
                  >
                    <div
                      style={{
                        background: 'url(\'../framerusercontent.com/images/rR6HYXBrMmX4cRpXfXUOvpvpB0.png\')',
                        backgroundRepeat: 'repeat',
                        backgroundSize: '256px 256px',
                        height: '400%',
                        inset: '-200%',
                        opacity: '0.02',
                        position: 'absolute',
                        width: '400%'
                      }}
                     />
                  </div>
                </div>
                <div
                  className="framer-3tnfe8"
                  style={{
                    WebkitFilter: 'blur(32px)',
                    backgroundColor: 'rgb(0, 0, 0)',
                    filter: 'blur(32px)'
                  }}
                >
                  <div className="framer-5tbvbw">
                    <div
                      style={{
                        borderRadius: 'inherit',
                        bottom: '0',
                        left: '0',
                        position: 'absolute',
                        right: '0',
                        top: '0'
                      }}
                    >
                      <img
                        alt=""
                        sizes="(min-width: 1049px) and (max-width: 1792px) calc(min(184px, 100vw) * 1.2011), (min-width: 810px) and (max-width: 1048px) calc(min(184px, 100vw) * 1.2011), (min-width: 1793px) calc(min(184px, 100vw) * 1.2011), (max-width: 809px) calc(min(184px, 100vw) * 1.2011)"
                        src="/assets/images/JUYFnYhwGFpjVlGMHAdEQz2Uzt8.png"
                        srcSet="/assets/images/JUYFnYhwGFpjVlGMHAdEQz2Uzt8.png?scale-down-to=512 512w, /assets/images/JUYFnYhwGFpjVlGMHAdEQz2Uzt8.png?scale-down-to=1024 1024w, /assets/images/JUYFnYhwGFpjVlGMHAdEQz2Uzt8.png 1280w"
                        style={{
                          borderRadius: 'inherit',
                          display: 'block',
                          height: '100%',
                          imageRendering: 'auto',
                          objectFit: 'cover',
                          objectPosition: 'center',
                          width: '100%'
                        }}
                      />
                    </div>
                  </div>
                  <div className="framer-1r8l7tp-container">
                    <video
                      loop
                      muted
                      playsInline
                      src="/assets/videos/video-internway.mp4"
                      style={{
                        backgroundColor: 'rgba(0, 0, 0, 0)',
                        borderRadius: '0px',
                        cursor: 'auto',
                        display: 'block',
                        height: '100%',
                        objectFit: 'cover',
                        objectPosition: '50% 50%',
                        width: '100%'
                      }}
                    />
                  </div>
                </div>
                <div className="framer-1axnin4">
                  <div
                    className="framer-btp5e4"
                    style={{
                      backgroundColor: 'rgba(255, 255, 255, 0.1)',
                      borderBottomLeftRadius: '12px',
                      borderBottomRightRadius: '12px',
                      borderTopLeftRadius: '12px',
                      borderTopRightRadius: '12px'
                    }}
                  >
                    <div
                      className="framer-1o5m695"
                      style={{
                        borderBottomLeftRadius: '8px',
                        borderBottomRightRadius: '8px',
                        borderTopLeftRadius: '8px',
                        borderTopRightRadius: '8px'
                      }}
                    >
                      <div
                        style={{
                          borderRadius: 'inherit',
                          bottom: '0',
                          left: '0',
                          position: 'absolute',
                          right: '0',
                          top: '0'
                        }}
                      >
                        <img
                          alt=""
                          sizes="40px"
                          src="../framerusercontent.com/images/e7E1LZylTYNq7cDoxfc6mVAvtQ.png"
                          style={{
                            borderRadius: 'inherit',
                            display: 'block',
                            height: '100%',
                            imageRendering: 'auto',
                            objectFit: 'cover',
                            objectPosition: 'center',
                            width: '100%'
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <div className="ssr-variant hidden-u21snj hidden-vh577v hidden-72rtr7">
            <div
              className="framer-17i3ubv-container"
              style={{
                opacity: '0.001',
                transform: 'perspective(1200px) translateX(0px) translateY(32px) scale(1) rotate(0deg) translateZ(0)'
              }}
            >
              <a
                className="framer-uviIJ framer-1a2vike framer-v-1a2vike framer-1wv0y5h"
                href="wrapp.html"
                style={{
                  '--border-bottom-width': '1px',
                  '--border-color': 'rgba(255, 255, 255, 0.1)',
                  '--border-left-width': '1px',
                  '--border-right-width': '1px',
                  '--border-style': 'solid',
                  '--border-top-width': '1px',
                  backgroundColor: 'rgb(0, 0, 0)',
                  borderBottomLeftRadius: '24px',
                  borderBottomRightRadius: '24px',
                  borderTopLeftRadius: '24px',
                  borderTopRightRadius: '24px',
                  height: '100%',
                  width: '100%'
                }}
                tabIndex="0"
              >
                <div className="framer-19kjldf-container">
                  <div
                    style={{
                      height: '100%',
                      overflow: 'hidden',
                      position: 'relative',
                      width: '100%'
                    }}
                  >
                    <div
                      style={{
                        background: 'url(\'../framerusercontent.com/images/rR6HYXBrMmX4cRpXfXUOvpvpB0.png\')',
                        backgroundRepeat: 'repeat',
                        backgroundSize: '256px 256px',
                        height: '400%',
                        inset: '-200%',
                        opacity: '0.02',
                        position: 'absolute',
                        width: '400%'
                      }}
                     />
                  </div>
                </div>
                <div
                  className="framer-3tnfe8"
                  style={{
                    WebkitFilter: 'blur(32px)',
                    backgroundColor: 'rgb(0, 0, 0)',
                    filter: 'blur(32px)'
                  }}
                >
                  <div className="framer-5tbvbw">
                    <div
                      style={{
                        borderRadius: 'inherit',
                        bottom: '0',
                        left: '0',
                        position: 'absolute',
                        right: '0',
                        top: '0'
                      }}
                    >
                      <img
                        alt=""
                        sizes="(min-width: 1049px) and (max-width: 1792px) calc(min(184px, 100vw) * 1.2011), (min-width: 810px) and (max-width: 1048px) calc(min(184px, 100vw) * 1.2011), (min-width: 1793px) calc(min(184px, 100vw) * 1.2011), (max-width: 809px) calc(min(184px, 100vw) * 1.2011)"
                        src="/assets/images/JUYFnYhwGFpjVlGMHAdEQz2Uzt8.png"
                        srcSet="/assets/images/JUYFnYhwGFpjVlGMHAdEQz2Uzt8.png?scale-down-to=512 512w, /assets/images/JUYFnYhwGFpjVlGMHAdEQz2Uzt8.png?scale-down-to=1024 1024w, /assets/images/JUYFnYhwGFpjVlGMHAdEQz2Uzt8.png 1280w"
                        style={{
                          borderRadius: 'inherit',
                          display: 'block',
                          height: '100%',
                          imageRendering: 'auto',
                          objectFit: 'cover',
                          objectPosition: 'center',
                          width: '100%'
                        }}
                      />
                    </div>
                  </div>
                  <div className="framer-1r8l7tp-container">
                    <video
                      loop
                      muted
                      playsInline
                      src="/assets/videos/video-internway.mp4"
                      style={{
                        backgroundColor: 'rgba(0, 0, 0, 0)',
                        borderRadius: '0px',
                        cursor: 'auto',
                        display: 'block',
                        height: '100%',
                        objectFit: 'cover',
                        objectPosition: '50% 50%',
                        width: '100%'
                      }}
                    />
                  </div>
                </div>
                <div className="framer-1axnin4">
                  <div
                    className="framer-btp5e4"
                    style={{
                      backgroundColor: 'rgba(255, 255, 255, 0.1)',
                      borderBottomLeftRadius: '12px',
                      borderBottomRightRadius: '12px',
                      borderTopLeftRadius: '12px',
                      borderTopRightRadius: '12px'
                    }}
                  >
                    <div
                      className="framer-1o5m695"
                      style={{
                        borderBottomLeftRadius: '8px',
                        borderBottomRightRadius: '8px',
                        borderTopLeftRadius: '8px',
                        borderTopRightRadius: '8px'
                      }}
                    >
                      <div
                        style={{
                          borderRadius: 'inherit',
                          bottom: '0',
                          left: '0',
                          position: 'absolute',
                          right: '0',
                          top: '0'
                        }}
                      >
                        <img
                          alt=""
                          sizes="40px"
                          src="../framerusercontent.com/images/e7E1LZylTYNq7cDoxfc6mVAvtQ.png"
                          style={{
                            borderRadius: 'inherit',
                            display: 'block',
                            height: '100%',
                            imageRendering: 'auto',
                            objectFit: 'cover',
                            objectPosition: 'center',
                            width: '100%'
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <div className="ssr-variant hidden-175z5sy hidden-vh577v hidden-72rtr7">
            <div
              className="framer-17i3ubv-container"
              style={{
                opacity: '0.001',
                transform: 'perspective(1200px) translateX(0px) translateY(32px) scale(1) rotate(0deg) translateZ(0)'
              }}
            >
              <a
                className="framer-uviIJ framer-1a2vike framer-v-1a2vike framer-1wv0y5h"
                href="wrapp.html"
                style={{
                  '--border-bottom-width': '1px',
                  '--border-color': 'rgba(255, 255, 255, 0.1)',
                  '--border-left-width': '1px',
                  '--border-right-width': '1px',
                  '--border-style': 'solid',
                  '--border-top-width': '1px',
                  backgroundColor: 'rgb(0, 0, 0)',
                  borderBottomLeftRadius: '24px',
                  borderBottomRightRadius: '24px',
                  borderTopLeftRadius: '24px',
                  borderTopRightRadius: '24px',
                  height: '100%',
                  width: '100%'
                }}
                tabIndex="0"
              >
                <div className="framer-19kjldf-container">
                  <div
                    style={{
                      height: '100%',
                      overflow: 'hidden',
                      position: 'relative',
                      width: '100%'
                    }}
                  >
                    <div
                      style={{
                        background: 'url(\'../framerusercontent.com/images/rR6HYXBrMmX4cRpXfXUOvpvpB0.png\')',
                        backgroundRepeat: 'repeat',
                        backgroundSize: '256px 256px',
                        height: '400%',
                        inset: '-200%',
                        opacity: '0.02',
                        position: 'absolute',
                        width: '400%'
                      }}
                     />
                  </div>
                </div>
                <div
                  className="framer-3tnfe8"
                  style={{
                    WebkitFilter: 'blur(32px)',
                    backgroundColor: 'rgb(0, 0, 0)',
                    filter: 'blur(32px)'
                  }}
                >
                  <div className="framer-5tbvbw">
                    <div
                      style={{
                        borderRadius: 'inherit',
                        bottom: '0',
                        left: '0',
                        position: 'absolute',
                        right: '0',
                        top: '0'
                      }}
                    >
                      <img
                        alt=""
                        sizes="(min-width: 1049px) and (max-width: 1792px) calc(min(184px, 100vw) * 1.2011), (min-width: 810px) and (max-width: 1048px) calc(min(184px, 100vw) * 1.2011), (min-width: 1793px) calc(min(184px, 100vw) * 1.2011), (max-width: 809px) calc(min(184px, 100vw) * 1.2011)"
                        src="/assets/images/JUYFnYhwGFpjVlGMHAdEQz2Uzt8.png"
                        srcSet="/assets/images/JUYFnYhwGFpjVlGMHAdEQz2Uzt8.png?scale-down-to=512 512w, /assets/images/JUYFnYhwGFpjVlGMHAdEQz2Uzt8.png?scale-down-to=1024 1024w, /assets/images/JUYFnYhwGFpjVlGMHAdEQz2Uzt8.png 1280w"
                        style={{
                          borderRadius: 'inherit',
                          display: 'block',
                          height: '100%',
                          imageRendering: 'auto',
                          objectFit: 'cover',
                          objectPosition: 'center',
                          width: '100%'
                        }}
                      />
                    </div>
                  </div>
                  <div className="framer-1r8l7tp-container">
                    <video
                      loop
                      muted
                      playsInline
                      src="/assets/videos/video-internway.mp4"
                      style={{
                        backgroundColor: 'rgba(0, 0, 0, 0)',
                        borderRadius: '0px',
                        cursor: 'auto',
                        display: 'block',
                        height: '100%',
                        objectFit: 'cover',
                        objectPosition: '50% 50%',
                        width: '100%'
                      }}
                    />
                  </div>
                </div>
                <div className="framer-1axnin4">
                  <div
                    className="framer-btp5e4"
                    style={{
                      backgroundColor: 'rgba(255, 255, 255, 0.1)',
                      borderBottomLeftRadius: '12px',
                      borderBottomRightRadius: '12px',
                      borderTopLeftRadius: '12px',
                      borderTopRightRadius: '12px'
                    }}
                  >
                    <div
                      className="framer-1o5m695"
                      style={{
                        borderBottomLeftRadius: '8px',
                        borderBottomRightRadius: '8px',
                        borderTopLeftRadius: '8px',
                        borderTopRightRadius: '8px'
                      }}
                    >
                      <div
                        style={{
                          borderRadius: 'inherit',
                          bottom: '0',
                          left: '0',
                          position: 'absolute',
                          right: '0',
                          top: '0'
                        }}
                      >
                        <img
                          alt=""
                          sizes="40px"
                          src="../framerusercontent.com/images/e7E1LZylTYNq7cDoxfc6mVAvtQ.png"
                          style={{
                            borderRadius: 'inherit',
                            display: 'block',
                            height: '100%',
                            imageRendering: 'auto',
                            objectFit: 'cover',
                            objectPosition: 'center',
                            width: '100%'
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <div className="ssr-variant hidden-175z5sy hidden-u21snj hidden-72rtr7">
            <div
              className="framer-17i3ubv-container"
              style={{
                opacity: '0.001',
                transform: 'perspective(1200px) translateX(0px) translateY(32px) scale(1) rotate(0deg) translateZ(0)'
              }}
            >
              <a
                className="framer-uviIJ framer-1a2vike framer-v-4kcy1m framer-1wv0y5h"
                style={{
                  '--border-bottom-width': '1px',
                  '--border-color': 'rgba(255, 255, 255, 0.1)',
                  '--border-left-width': '1px',
                  '--border-right-width': '1px',
                  '--border-style': 'solid',
                  '--border-top-width': '1px',
                  backgroundColor: 'rgb(0, 0, 0)',
                  borderBottomLeftRadius: '24px',
                  borderBottomRightRadius: '24px',
                  borderTopLeftRadius: '24px',
                  borderTopRightRadius: '24px',
                  height: '100%',
                  width: '100%'
                }}
                tabIndex="0"
              >
                <div className="framer-19kjldf-container">
                  <div
                    style={{
                      height: '100%',
                      overflow: 'hidden',
                      position: 'relative',
                      width: '100%'
                    }}
                  >
                    <div
                      style={{
                        background: 'url(\'../framerusercontent.com/images/rR6HYXBrMmX4cRpXfXUOvpvpB0.png\')',
                        backgroundRepeat: 'repeat',
                        backgroundSize: '256px 256px',
                        height: '400%',
                        inset: '-200%',
                        opacity: '0.02',
                        position: 'absolute',
                        width: '400%'
                      }}
                     />
                  </div>
                </div>
                <div
                  className="framer-3tnfe8"
                  style={{
                    WebkitFilter: 'blur(32px)',
                    backgroundColor: 'rgb(0, 0, 0)',
                    filter: 'blur(32px)'
                  }}
                >
                  <div className="framer-5tbvbw">
                    <div
                      style={{
                        borderRadius: 'inherit',
                        bottom: '0',
                        left: '0',
                        position: 'absolute',
                        right: '0',
                        top: '0'
                      }}
                    >
                      <img
                        alt=""
                        sizes="(min-width: 1049px) and (max-width: 1792px) calc(min(184px, 100vw) * 1.2011), (min-width: 810px) and (max-width: 1048px) calc(min(184px, 100vw) * 1.2011), (min-width: 1793px) calc(min(184px, 100vw) * 1.2011), (max-width: 809px) calc(min(184px, 100vw) * 1.2011)"
                        src="/assets/images/JUYFnYhwGFpjVlGMHAdEQz2Uzt8.png"
                        srcSet="/assets/images/JUYFnYhwGFpjVlGMHAdEQz2Uzt8.png?scale-down-to=512 512w, /assets/images/JUYFnYhwGFpjVlGMHAdEQz2Uzt8.png?scale-down-to=1024 1024w, /assets/images/JUYFnYhwGFpjVlGMHAdEQz2Uzt8.png 1280w"
                        style={{
                          borderRadius: 'inherit',
                          display: 'block',
                          height: '100%',
                          imageRendering: 'auto',
                          objectFit: 'cover',
                          objectPosition: 'center',
                          width: '100%'
                        }}
                      />
                    </div>
                  </div>
                  <div className="framer-1r8l7tp-container">
                    <video
                      loop
                      muted
                      playsInline
                      src="https://framerusercontent.com/assets/8TpCW4SRXR5Fye70QJ5VNdT7o.mp4"
                      style={{
                        backgroundColor: 'rgba(0, 0, 0, 0)',
                        borderRadius: '0px',
                        cursor: 'auto',
                        display: 'block',
                        height: '100%',
                        objectFit: 'cover',
                        objectPosition: '50% 50%',
                        width: '100%'
                      }}
                    />
                  </div>
                </div>
                <div className="framer-1axnin4">
                  <div
                    className="framer-btp5e4"
                    style={{
                      backgroundColor: 'rgba(255, 255, 255, 0.1)',
                      borderBottomLeftRadius: '12px',
                      borderBottomRightRadius: '12px',
                      borderTopLeftRadius: '12px',
                      borderTopRightRadius: '12px'
                    }}
                  >
                    <div
                      className="framer-1o5m695"
                      style={{
                        borderBottomLeftRadius: '8px',
                        borderBottomRightRadius: '8px',
                        borderTopLeftRadius: '8px',
                        borderTopRightRadius: '8px'
                      }}
                    >
                      <div
                        style={{
                          borderRadius: 'inherit',
                          bottom: '0',
                          left: '0',
                          position: 'absolute',
                          right: '0',
                          top: '0'
                        }}
                      >
                        <img
                          alt=""
                          sizes="40px"
                          src="../framerusercontent.com/images/e7E1LZylTYNq7cDoxfc6mVAvtQ.png"
                          style={{
                            borderRadius: 'inherit',
                            display: 'block',
                            height: '100%',
                            imageRendering: 'auto',
                            objectFit: 'cover',
                            objectPosition: 'center',
                            width: '100%'
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div className="ssr-variant hidden-175z5sy hidden-u21snj hidden-vh577v">
          <div
            className="framer-1gwdrm4-container"
            style={{
              opacity: '0.001',
              transform: 'perspective(1200px) translateX(32px) translateY(0px) scale(1) rotate(0deg) translateZ(0)'
            }}
          >
            <a
              className="framer-uviIJ framer-1a2vike framer-v-1a2vike framer-1wv0y5h"
              href="proximity.html"
              style={{
                '--border-bottom-width': '1px',
                '--border-color': 'rgba(255, 255, 255, 0.1)',
                '--border-left-width': '1px',
                '--border-right-width': '1px',
                '--border-style': 'solid',
                '--border-top-width': '1px',
                backgroundColor: 'rgb(0, 0, 0)',
                borderBottomLeftRadius: '24px',
                borderBottomRightRadius: '24px',
                borderTopLeftRadius: '24px',
                borderTopRightRadius: '24px',
                height: '100%',
                width: '100%'
              }}
              tabIndex="0"
            >
              <div className="framer-19kjldf-container">
                <div
                  style={{
                    height: '100%',
                    overflow: 'hidden',
                    position: 'relative',
                    width: '100%'
                  }}
                >
                  <div
                    style={{
                      background: 'url(\'../framerusercontent.com/images/rR6HYXBrMmX4cRpXfXUOvpvpB0.png\')',
                      backgroundRepeat: 'repeat',
                      backgroundSize: '256px 256px',
                      height: '400%',
                      inset: '-200%',
                      opacity: '0.02',
                      position: 'absolute',
                      width: '400%'
                    }}
                   />
                </div>
              </div>
              <div
                className="framer-3tnfe8"
                style={{
                  WebkitFilter: 'blur(32px)',
                  backgroundColor: 'rgb(0, 0, 0)',
                  filter: 'blur(32px)'
                }}
              >
                <div className="framer-5tbvbw">
                  <div
                    style={{
                      borderRadius: 'inherit',
                      bottom: '0',
                      left: '0',
                      position: 'absolute',
                      right: '0',
                      top: '0'
                    }}
                  >
                    <img
                      alt=""
                      sizes="(min-width: 1049px) and (max-width: 1792px) calc(min(184px, 100vw) * 1.2011), (min-width: 810px) and (max-width: 1048px) calc(min(184px, 100vw) * 1.2011), (min-width: 1793px) calc(min(184px, 100vw) * 1.2011), (max-width: 809px) calc(min(184px, 100vw) * 1.2011)"
                      src="https://framerusercontent.com/images/Z0ghpfCmkXUGEelQXAhWn2Qxfo.webp"
                      srcSet="https://framerusercontent.com/images/Z0ghpfCmkXUGEelQXAhWn2Qxfo.webp?scale-down-to=512 512w, https://framerusercontent.com/images/Z0ghpfCmkXUGEelQXAhWn2Qxfo.webp?scale-down-to=1024 1024w, https://framerusercontent.com/images/Z0ghpfCmkXUGEelQXAhWn2Qxfo.webp?scale-down-to=2048 2048w, https://framerusercontent.com/images/Z0ghpfCmkXUGEelQXAhWn2Qxfo.webp 3456w"
                      style={{
                        borderRadius: 'inherit',
                        display: 'block',
                        height: '100%',
                        imageRendering: 'auto',
                        objectFit: 'cover',
                        objectPosition: 'center',
                        width: '100%'
                      }}
                    />
                  </div>
                </div>
                <div className="framer-1r8l7tp-container">
                  <video
                    loop
                    muted
                    playsInline
                    src="/assets/videos/video-xaalys.mp4"
                    style={{
                      backgroundColor: 'rgba(0, 0, 0, 0)',
                      borderRadius: '0px',
                      cursor: 'auto',
                      display: 'block',
                      height: '100%',
                      objectFit: 'cover',
                      objectPosition: '50% 50%',
                      width: '100%'
                    }}
                  />
                </div>
              </div>
              <div className="framer-1axnin4">
                <div
                  className="framer-btp5e4"
                  style={{
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    borderBottomLeftRadius: '12px',
                    borderBottomRightRadius: '12px',
                    borderTopLeftRadius: '12px',
                    borderTopRightRadius: '12px'
                  }}
                >
                  <div
                    className="framer-1o5m695"
                    style={{
                      borderBottomLeftRadius: '8px',
                      borderBottomRightRadius: '8px',
                      borderTopLeftRadius: '8px',
                      borderTopRightRadius: '8px'
                    }}
                  >
                    <div
                      style={{
                        borderRadius: 'inherit',
                        bottom: '0',
                        left: '0',
                        position: 'absolute',
                        right: '0',
                        top: '0'
                      }}
                    >
                      <img
                        alt=""
                        sizes="40px"
                        src="../framerusercontent.com/images/gRRxfy1Aoz5c1EYFFJ80YotzjZY.png"
                        style={{
                          borderRadius: 'inherit',
                          display: 'block',
                          height: '100%',
                          imageRendering: 'auto',
                          objectFit: 'cover',
                          objectPosition: 'center',
                          width: '100%'
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div className="ssr-variant hidden-u21snj hidden-vh577v hidden-72rtr7">
          <div
            className="framer-1gwdrm4-container"
            style={{
              opacity: '0.001',
              transform: 'perspective(1200px) translateX(32px) translateY(0px) scale(1) rotate(0deg) translateZ(0)'
            }}
          >
            <a
              className="framer-uviIJ framer-1a2vike framer-v-1a2vike framer-1wv0y5h"
              href="proximity.html"
              style={{
                '--border-bottom-width': '1px',
                '--border-color': 'rgba(255, 255, 255, 0.1)',
                '--border-left-width': '1px',
                '--border-right-width': '1px',
                '--border-style': 'solid',
                '--border-top-width': '1px',
                backgroundColor: 'rgb(0, 0, 0)',
                borderBottomLeftRadius: '24px',
                borderBottomRightRadius: '24px',
                borderTopLeftRadius: '24px',
                borderTopRightRadius: '24px',
                height: '100%',
                width: '100%'
              }}
              tabIndex="0"
            >
              <div className="framer-19kjldf-container">
                <div
                  style={{
                    height: '100%',
                    overflow: 'hidden',
                    position: 'relative',
                    width: '100%'
                  }}
                >
                  <div
                    style={{
                      background: 'url(\'../framerusercontent.com/images/rR6HYXBrMmX4cRpXfXUOvpvpB0.png\')',
                      backgroundRepeat: 'repeat',
                      backgroundSize: '256px 256px',
                      height: '400%',
                      inset: '-200%',
                      opacity: '0.02',
                      position: 'absolute',
                      width: '400%'
                    }}
                   />
                </div>
              </div>
              <div
                className="framer-3tnfe8"
                style={{
                  WebkitFilter: 'blur(32px)',
                  backgroundColor: 'rgb(0, 0, 0)',
                  filter: 'blur(32px)'
                }}
              >
                <div className="framer-5tbvbw">
                  <div
                    style={{
                      borderRadius: 'inherit',
                      bottom: '0',
                      left: '0',
                      position: 'absolute',
                      right: '0',
                      top: '0'
                    }}
                  >
                    <img
                      alt=""
                      sizes="(min-width: 1049px) and (max-width: 1792px) calc(min(184px, 100vw) * 1.2011), (min-width: 810px) and (max-width: 1048px) calc(min(184px, 100vw) * 1.2011), (min-width: 1793px) calc(min(184px, 100vw) * 1.2011), (max-width: 809px) calc(min(184px, 100vw) * 1.2011)"
                      src="https://framerusercontent.com/images/Z0ghpfCmkXUGEelQXAhWn2Qxfo.webp"
                      srcSet="https://framerusercontent.com/images/Z0ghpfCmkXUGEelQXAhWn2Qxfo.webp?scale-down-to=512 512w, https://framerusercontent.com/images/Z0ghpfCmkXUGEelQXAhWn2Qxfo.webp?scale-down-to=1024 1024w, https://framerusercontent.com/images/Z0ghpfCmkXUGEelQXAhWn2Qxfo.webp?scale-down-to=2048 2048w, https://framerusercontent.com/images/Z0ghpfCmkXUGEelQXAhWn2Qxfo.webp 3456w"
                      style={{
                        borderRadius: 'inherit',
                        display: 'block',
                        height: '100%',
                        imageRendering: 'auto',
                        objectFit: 'cover',
                        objectPosition: 'center',
                        width: '100%'
                      }}
                    />
                  </div>
                </div>
                <div className="framer-1r8l7tp-container">
                  <video
                    loop
                    muted
                    playsInline
                    src="/assets/videos/video-xaalys.mp4"
                    style={{
                      backgroundColor: 'rgba(0, 0, 0, 0)',
                      borderRadius: '0px',
                      cursor: 'auto',
                      display: 'block',
                      height: '100%',
                      objectFit: 'cover',
                      objectPosition: '50% 50%',
                      width: '100%'
                    }}
                  />
                </div>
              </div>
              <div className="framer-1axnin4">
                <div
                  className="framer-btp5e4"
                  style={{
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    borderBottomLeftRadius: '12px',
                    borderBottomRightRadius: '12px',
                    borderTopLeftRadius: '12px',
                    borderTopRightRadius: '12px'
                  }}
                >
                  <div
                    className="framer-1o5m695"
                    style={{
                      borderBottomLeftRadius: '8px',
                      borderBottomRightRadius: '8px',
                      borderTopLeftRadius: '8px',
                      borderTopRightRadius: '8px'
                    }}
                  >
                    <div
                      style={{
                        borderRadius: 'inherit',
                        bottom: '0',
                        left: '0',
                        position: 'absolute',
                        right: '0',
                        top: '0'
                      }}
                    >
                      <img
                        alt=""
                        sizes="40px"
                        src="../framerusercontent.com/images/gRRxfy1Aoz5c1EYFFJ80YotzjZY.png"
                        style={{
                          borderRadius: 'inherit',
                          display: 'block',
                          height: '100%',
                          imageRendering: 'auto',
                          objectFit: 'cover',
                          objectPosition: 'center',
                          width: '100%'
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div className="ssr-variant hidden-175z5sy hidden-vh577v hidden-72rtr7">
          <div
            className="framer-1gwdrm4-container"
            style={{
              opacity: '0.001',
              transform: 'perspective(1200px) translateX(32px) translateY(0px) scale(1) rotate(0deg) translateZ(0)'
            }}
          >
            <a
              className="framer-uviIJ framer-1a2vike framer-v-1a2vike framer-1wv0y5h"
              href="proximity.html"
              style={{
                '--border-bottom-width': '1px',
                '--border-color': 'rgba(255, 255, 255, 0.1)',
                '--border-left-width': '1px',
                '--border-right-width': '1px',
                '--border-style': 'solid',
                '--border-top-width': '1px',
                backgroundColor: 'rgb(0, 0, 0)',
                borderBottomLeftRadius: '24px',
                borderBottomRightRadius: '24px',
                borderTopLeftRadius: '24px',
                borderTopRightRadius: '24px',
                height: '100%',
                width: '100%'
              }}
              tabIndex="0"
            >
              <div className="framer-19kjldf-container">
                <div
                  style={{
                    height: '100%',
                    overflow: 'hidden',
                    position: 'relative',
                    width: '100%'
                  }}
                >
                  <div
                    style={{
                      background: 'url(\'../framerusercontent.com/images/rR6HYXBrMmX4cRpXfXUOvpvpB0.png\')',
                      backgroundRepeat: 'repeat',
                      backgroundSize: '256px 256px',
                      height: '400%',
                      inset: '-200%',
                      opacity: '0.02',
                      position: 'absolute',
                      width: '400%'
                    }}
                   />
                </div>
              </div>
              <div
                className="framer-3tnfe8"
                style={{
                  WebkitFilter: 'blur(32px)',
                  backgroundColor: 'rgb(0, 0, 0)',
                  filter: 'blur(32px)'
                }}
              >
                <div className="framer-5tbvbw">
                  <div
                    style={{
                      borderRadius: 'inherit',
                      bottom: '0',
                      left: '0',
                      position: 'absolute',
                      right: '0',
                      top: '0'
                    }}
                  >
                    <img
                      alt=""
                      sizes="(min-width: 1049px) and (max-width: 1792px) calc(min(184px, 100vw) * 1.2011), (min-width: 810px) and (max-width: 1048px) calc(min(184px, 100vw) * 1.2011), (min-width: 1793px) calc(min(184px, 100vw) * 1.2011), (max-width: 809px) calc(min(184px, 100vw) * 1.2011)"
                      src="https://framerusercontent.com/images/Z0ghpfCmkXUGEelQXAhWn2Qxfo.webp"
                      srcSet="https://framerusercontent.com/images/Z0ghpfCmkXUGEelQXAhWn2Qxfo.webp?scale-down-to=512 512w, https://framerusercontent.com/images/Z0ghpfCmkXUGEelQXAhWn2Qxfo.webp?scale-down-to=1024 1024w, https://framerusercontent.com/images/Z0ghpfCmkXUGEelQXAhWn2Qxfo.webp?scale-down-to=2048 2048w, https://framerusercontent.com/images/Z0ghpfCmkXUGEelQXAhWn2Qxfo.webp 3456w"
                      style={{
                        borderRadius: 'inherit',
                        display: 'block',
                        height: '100%',
                        imageRendering: 'auto',
                        objectFit: 'cover',
                        objectPosition: 'center',
                        width: '100%'
                      }}
                    />
                  </div>
                </div>
                <div className="framer-1r8l7tp-container">
                  <video
                    loop
                    muted
                    playsInline
                    src="/assets/videos/video-xaalys.mp4"
                    style={{
                      backgroundColor: 'rgba(0, 0, 0, 0)',
                      borderRadius: '0px',
                      cursor: 'auto',
                      display: 'block',
                      height: '100%',
                      objectFit: 'cover',
                      objectPosition: '50% 50%',
                      width: '100%'
                    }}
                  />
                </div>
              </div>
              <div className="framer-1axnin4">
                <div
                  className="framer-btp5e4"
                  style={{
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    borderBottomLeftRadius: '12px',
                    borderBottomRightRadius: '12px',
                    borderTopLeftRadius: '12px',
                    borderTopRightRadius: '12px'
                  }}
                >
                  <div
                    className="framer-1o5m695"
                    style={{
                      borderBottomLeftRadius: '8px',
                      borderBottomRightRadius: '8px',
                      borderTopLeftRadius: '8px',
                      borderTopRightRadius: '8px'
                    }}
                  >
                    <div
                      style={{
                        borderRadius: 'inherit',
                        bottom: '0',
                        left: '0',
                        position: 'absolute',
                        right: '0',
                        top: '0'
                      }}
                    >
                      <img
                        alt=""
                        sizes="40px"
                        src="../framerusercontent.com/images/gRRxfy1Aoz5c1EYFFJ80YotzjZY.png"
                        style={{
                          borderRadius: 'inherit',
                          display: 'block',
                          height: '100%',
                          imageRendering: 'auto',
                          objectFit: 'cover',
                          objectPosition: 'center',
                          width: '100%'
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div className="ssr-variant hidden-175z5sy hidden-u21snj hidden-72rtr7">
          <div
            className="framer-1gwdrm4-container"
            style={{
              opacity: '0.001',
              transform: 'perspective(1200px) translateX(32px) translateY(0px) scale(1) rotate(0deg) translateZ(0)'
            }}
          >
            <a
              className="framer-uviIJ framer-1a2vike framer-v-4kcy1m framer-1wv0y5h"
              style={{
                '--border-bottom-width': '1px',
                '--border-color': 'rgba(255, 255, 255, 0.1)',
                '--border-left-width': '1px',
                '--border-right-width': '1px',
                '--border-style': 'solid',
                '--border-top-width': '1px',
                backgroundColor: 'rgb(0, 0, 0)',
                borderBottomLeftRadius: '24px',
                borderBottomRightRadius: '24px',
                borderTopLeftRadius: '24px',
                borderTopRightRadius: '24px',
                height: '100%',
                width: '100%'
              }}
              tabIndex="0"
            >
              <div className="framer-19kjldf-container">
                <div
                  style={{
                    height: '100%',
                    overflow: 'hidden',
                    position: 'relative',
                    width: '100%'
                  }}
                >
                  <div
                    style={{
                      background: 'url(\'../framerusercontent.com/images/rR6HYXBrMmX4cRpXfXUOvpvpB0.png\')',
                      backgroundRepeat: 'repeat',
                      backgroundSize: '256px 256px',
                      height: '400%',
                      inset: '-200%',
                      opacity: '0.02',
                      position: 'absolute',
                      width: '400%'
                    }}
                   />
                </div>
              </div>
              <div
                className="framer-3tnfe8"
                style={{
                  WebkitFilter: 'blur(32px)',
                  backgroundColor: 'rgb(0, 0, 0)',
                  filter: 'blur(32px)'
                }}
              >
                <div className="framer-5tbvbw">
                  <div
                    style={{
                      borderRadius: 'inherit',
                      bottom: '0',
                      left: '0',
                      position: 'absolute',
                      right: '0',
                      top: '0'
                    }}
                  >
                    <img
                      alt=""
                      sizes="(min-width: 1049px) and (max-width: 1792px) calc(min(184px, 100vw) * 1.2011), (min-width: 810px) and (max-width: 1048px) calc(min(184px, 100vw) * 1.2011), (min-width: 1793px) calc(min(184px, 100vw) * 1.2011), (max-width: 809px) calc(min(184px, 100vw) * 1.2011)"
                      src="https://framerusercontent.com/images/Z0ghpfCmkXUGEelQXAhWn2Qxfo.webp"
                      srcSet="https://framerusercontent.com/images/Z0ghpfCmkXUGEelQXAhWn2Qxfo.webp?scale-down-to=512 512w, https://framerusercontent.com/images/Z0ghpfCmkXUGEelQXAhWn2Qxfo.webp?scale-down-to=1024 1024w, https://framerusercontent.com/images/Z0ghpfCmkXUGEelQXAhWn2Qxfo.webp?scale-down-to=2048 2048w, https://framerusercontent.com/images/Z0ghpfCmkXUGEelQXAhWn2Qxfo.webp 3456w"
                      style={{
                        borderRadius: 'inherit',
                        display: 'block',
                        height: '100%',
                        imageRendering: 'auto',
                        objectFit: 'cover',
                        objectPosition: 'center',
                        width: '100%'
                      }}
                    />
                  </div>
                </div>
                <div className="framer-1r8l7tp-container">
                  <video
                    loop
                    muted
                    playsInline
                    src="https://framerusercontent.com/assets/TbJVUCmlvTLikp5IwguA7MiML8.mp4"
                    style={{
                      backgroundColor: 'rgba(0, 0, 0, 0)',
                      borderRadius: '0px',
                      cursor: 'auto',
                      display: 'block',
                      height: '100%',
                      objectFit: 'cover',
                      objectPosition: '50% 50%',
                      width: '100%'
                    }}
                  />
                </div>
              </div>
              <div className="framer-1axnin4">
                <div
                  className="framer-btp5e4"
                  style={{
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    borderBottomLeftRadius: '12px',
                    borderBottomRightRadius: '12px',
                    borderTopLeftRadius: '12px',
                    borderTopRightRadius: '12px'
                  }}
                >
                  <div
                    className="framer-1o5m695"
                    style={{
                      borderBottomLeftRadius: '8px',
                      borderBottomRightRadius: '8px',
                      borderTopLeftRadius: '8px',
                      borderTopRightRadius: '8px'
                    }}
                  >
                    <div
                      style={{
                        borderRadius: 'inherit',
                        bottom: '0',
                        left: '0',
                        position: 'absolute',
                        right: '0',
                        top: '0'
                      }}
                    >
                      <img
                        alt=""
                        sizes="40px"
                        src="../framerusercontent.com/images/gRRxfy1Aoz5c1EYFFJ80YotzjZY.png"
                        style={{
                          borderRadius: 'inherit',
                          display: 'block',
                          height: '100%',
                          imageRendering: 'auto',
                          objectFit: 'cover',
                          objectPosition: 'center',
                          width: '100%'
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
    <footer
      className="framer-n8r0nu"
      id="footer"
      name="Footer"
    >
      <div
        className="framer-199jvhg"
        name="Content"
      >
        <div className="ssr-variant hidden-vh577v">
          <div
            className="framer-8rfv6o"
            name="Img"
            style={{
              opacity: '0',
              transform: 'perspective(1200px)'
            }}
          >
            <div
              className="framer-r04ll9"
              name="Gradient"
            />
            <div className="ssr-variant hidden-175z5sy">
              <div
                className="framer-18xyfpk"
                name="Image"
              >
                <div
                  style={{
                    borderRadius: 'inherit',
                    bottom: '0',
                    left: '0',
                    position: 'absolute',
                    right: '0',
                    top: '0'
                  }}
                >
                  <img
                    alt=""
                    sizes="(min-width: 1793px) 440px, (min-width: 1049px) and (max-width: 1792px) 440px, (min-width: 810px) and (max-width: 1048px) 440px, (max-width: 809px) 235.125px"
                    src="./framerusercontent.com/images/NQiQhJItUOJXEYcBRuvacL9YrY.png"
                    srcSet="/assets/images/photo_profile-1.png?scale-down-to=512 512w, /assets/images/photo_profile-1.png?scale-down-to=1024 1024w, /assets/images/photo_profile-1.png?scale-down-to=2048 2048w, /assets/images/photo_profile-1.png 2316w"
                    style={{
                      borderRadius: 'inherit',
                      display: 'block',
                      height: '100%',
                      imageRendering: 'auto',
                      objectFit: 'cover',
                      objectPosition: 'center',
                      width: '100%'
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="ssr-variant hidden-u21snj hidden-72rtr7">
              <div
                className="framer-18xyfpk"
                name="Image"
              >
                <div
                  style={{
                    borderRadius: 'inherit',
                    bottom: '0',
                    left: '0',
                    position: 'absolute',
                    right: '0',
                    top: '0'
                  }}
                >
                  <img
                    alt=""
                    loading="lazy"
                    sizes="(min-width: 1793px) 440px, (min-width: 1049px) and (max-width: 1792px) 440px, (min-width: 810px) and (max-width: 1048px) 440px, (max-width: 809px) 235.125px"
                    src="../framerusercontent.com/images/NQiQhJItUOJXEYcBRuvacL9YrY.png"
                    srcSet="/assets/images/photo_profile-1.png?scale-down-to=512 512w, /assets/images/photo_profile-1.png?scale-down-to=1024 1024w, /assets/images/photo_profile-1.png?scale-down-to=2048 2048w, /assets/images/photo_profile-1.png 2316w"
                    style={{
                      borderRadius: 'inherit',
                      display: 'block',
                      height: '100%',
                      imageRendering: 'auto',
                      objectFit: 'cover',
                      objectPosition: 'center',
                      width: '100%'
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="ssr-variant hidden-175z5sy hidden-u21snj hidden-72rtr7">
          <div
            className="framer-8rfv6o"
            name="Img"
            style={{
              opacity: '0',
              transform: 'perspective(1200px)'
            }}
          >
            <div
              className="framer-r04ll9"
              name="Gradient"
            />
            <div
              className="framer-18xyfpk"
              name="Image"
            >
              <div
                style={{
                  borderRadius: 'inherit',
                  bottom: '0',
                  left: '0',
                  position: 'absolute',
                  right: '0',
                  top: '0'
                }}
              >
                <img
                  alt=""
                  loading="lazy"
                  sizes="(min-width: 1793px) 440px, (min-width: 1049px) and (max-width: 1792px) 440px, (min-width: 810px) and (max-width: 1048px) 440px, (max-width: 809px) 235.125px"
                  src="../framerusercontent.com/images/NQiQhJItUOJXEYcBRuvacL9YrY.png"
                  srcSet="/assets/images/photo_profile-1.png?scale-down-to=512 512w, /assets/images/photo_profile-1.png?scale-down-to=1024 1024w, /assets/images/photo_profile-1.png?scale-down-to=2048 2048w, /assets/images/photo_profile-1.png 2316w"
                  style={{
                    borderRadius: 'inherit',
                    display: 'block',
                    height: '100%',
                    imageRendering: 'auto',
                    objectFit: 'cover',
                    objectPosition: 'center',
                    width: '100%'
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div
          className="framer-g1ckc4"
          name="Content"
        >
          <div className="framer-1be9n5o">
            <div className="ssr-variant hidden-175z5sy hidden-vh577v">
              <div
                className="framer-1fwsy76"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  flexShrink: '0',
                  justifyContent: 'flex-start',
                  opacity: '0',
                  outline: 'none',
                  transform: 'perspective(1200px) translateX(0px) translateY(32px) scale(1) rotate(0deg) rotateX(0deg) rotateY(0deg) translateZ(0)'
                }}
              >
                <p
                  className="framer-text"
                  style={{
                    '--font-selector': 'R0Y7RmlndHJlZS1yZWd1bGFy',
                    '--framer-font-family': '"Figtree", "Figtree Placeholder", sans-serif',
                    '--framer-line-height': '150%',
                    '--framer-text-alignment': 'left',
                    '--framer-text-color': 'rgba(255, 255, 255, 0.6)'
                  }}
                >
                  Je suis un développeur avec plus de 5 ans d'expérience dans le développement web et mobile. J'ai eu le privilège de travailler sur une variété de projets passionnants, collaborant avec des équipes créatives et talentueuses. Explorez quelques-uns des projets auxquels j'ai participé et n'hésitez pas à me contacter si vous souhaitez collaborer sur un projet.{' '}
                </p>
                <p
                  className="framer-text"
                  style={{
                    '--font-selector': 'R0Y7RmlndHJlZS1yZWd1bGFy',
                    '--framer-font-family': '"Figtree", "Figtree Placeholder", sans-serif',
                    '--framer-line-height': '150%',
                    '--framer-text-alignment': 'left',
                    '--framer-text-color': 'rgba(255, 255, 255, 0.6)'
                  }}
                >
                  <br className="framer-text trailing-break" />
                </p>
                <p
                  className="framer-text"
                  style={{
                    '--font-selector': 'R0Y7RmlndHJlZS1yZWd1bGFy',
                    '--framer-font-family': '"Figtree", "Figtree Placeholder", sans-serif',
                    '--framer-line-height': '150%',
                    '--framer-text-alignment': 'left',
                    '--framer-text-color': 'rgba(255, 255, 255, 0.6)'
                  }}
                >
                  Je travaille également à la création de sites e-commerce, au sourcing de produits pour les clients, et à la mise en place de campagnes publicitaires pour aider à augmenter les ventes et rentabiliser les activités commerciales.{' '}
                  
                </p>
              </div>
            </div>
            <div className="ssr-variant hidden-u21snj hidden-vh577v hidden-72rtr7">
              <div
                className="framer-1fwsy76"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  flexShrink: '0',
                  justifyContent: 'flex-start',
                  opacity: '0',
                  outline: 'none',
                  transform: 'perspective(1200px) translateX(0px) translateY(32px) scale(1) rotate(0deg) rotateX(0deg) rotateY(0deg) translateZ(0)'
                }}
              >
                <p
                  className="framer-text"
                  style={{
                    '--font-selector': 'R0Y7RmlndHJlZS1yZWd1bGFy',
                    '--framer-font-family': '"Figtree", "Figtree Placeholder", sans-serif',
                    '--framer-line-height': '150%',
                    '--framer-text-alignment': 'center',
                    '--framer-text-color': 'rgba(255, 255, 255, 0.6)'
                  }}
                >
                  Je suis un développeur avec plus de 5 ans d'expérience dans le développement web et mobile. J'ai eu le privilège de travailler sur une variété de projets passionnants, collaborant avec des équipes créatives et talentueuses. Explorez quelques-uns des projets auxquels j'ai participé et n'hésitez pas à me contacter si vous souhaitez collaborer sur un projet.{' '}
                </p>
                <p
                  className="framer-text"
                  style={{
                    '--font-selector': 'R0Y7RmlndHJlZS1yZWd1bGFy',
                    '--framer-font-family': '"Figtree", "Figtree Placeholder", sans-serif',
                    '--framer-line-height': '150%',
                    '--framer-text-alignment': 'center',
                    '--framer-text-color': 'rgba(255, 255, 255, 0.6)'
                  }}
                >
                  <br className="framer-text trailing-break" />
                </p>
                <p
                  className="framer-text"
                  style={{
                    '--font-selector': 'R0Y7RmlndHJlZS1yZWd1bGFy',
                    '--framer-font-family': '"Figtree", "Figtree Placeholder", sans-serif',
                    '--framer-line-height': '150%',
                    '--framer-text-alignment': 'center',
                    '--framer-text-color': 'rgba(255, 255, 255, 0.6)'
                  }}
                >
                  Je travaille également à la création de sites e-commerce, au sourcing de produits pour les clients, et à la mise en place de campagnes publicitaires pour aider à augmenter les ventes et rentabiliser les activités commerciales.{' '}
                  
                </p>
              </div>
            </div>
            <div className="ssr-variant hidden-175z5sy hidden-u21snj hidden-72rtr7">
              <div
                className="framer-1fwsy76"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  flexShrink: '0',
                  justifyContent: 'flex-start',
                  opacity: '0',
                  outline: 'none',
                  transform: 'perspective(1200px) translateX(0px) translateY(32px) scale(1) rotate(0deg) rotateX(0deg) rotateY(0deg) translateZ(0)'
                }}
              >
                <p
                  className="framer-text"
                  style={{
                    '--font-selector': 'R0Y7RmlndHJlZS1yZWd1bGFy',
                    '--framer-font-family': '"Figtree", "Figtree Placeholder", sans-serif',
                    '--framer-line-height': '150%',
                    '--framer-text-alignment': 'center',
                    '--framer-text-color': 'rgba(255, 255, 255, 0.6)'
                  }}
                >
                  Je suis un développeur avec plus de 5 ans d'expérience dans le développement web et mobile. J'ai eu le privilège de travailler sur une variété de projets passionnants, collaborant avec des équipes créatives et talentueuses. Explorez quelques-uns des projets auxquels j'ai participé et n'hésitez pas à me contacter si vous souhaitez collaborer sur un projet.{' '}
                </p>
                <p
                  className="framer-text"
                  style={{
                    '--font-selector': 'R0Y7RmlndHJlZS1yZWd1bGFy',
                    '--framer-font-family': '"Figtree", "Figtree Placeholder", sans-serif',
                    '--framer-line-height': '150%',
                    '--framer-text-alignment': 'center',
                    '--framer-text-color': 'rgba(255, 255, 255, 0.6)'
                  }}
                >
                  <br className="framer-text trailing-break" />
                </p>
                <p
                  className="framer-text"
                  style={{
                    '--font-selector': 'R0Y7RmlndHJlZS1yZWd1bGFy',
                    '--framer-font-family': '"Figtree", "Figtree Placeholder", sans-serif',
                    '--framer-line-height': '150%',
                    '--framer-text-alignment': 'center',
                    '--framer-text-color': 'rgba(255, 255, 255, 0.6)'
                  }}
                >
                  Je travaille également à la création de sites e-commerce, au sourcing de produits pour les clients, et à la mise en place de campagnes publicitaires pour aider à augmenter les ventes et rentabiliser les activités commerciales.{' '}
                  
                </p>
              </div>
            </div>
          </div>
          <div
            className="framer-tcmoiu"
            name="Buttons"
          >
            <div
              className="framer-4afbwj-container"
              style={{
                opacity: '0',
                transform: 'perspective(1200px) translateX(0px) translateY(8px) scale(1) rotate(0deg) rotateX(0deg) rotateY(0deg) translateZ(0)'
              }}
            >
              <div className="ssr-variant hidden-175z5sy hidden-vh577v">
                <div
                  style={{
                    '--framer-custom-placeholder-color': 'rgba(255, 255, 255, 0.4)',
                    alignItems: 'center',
                    display: 'flex',
                    height: '100%',
                    justifyContent: 'center',
                    position: 'relative',
                    width: '100%'
                  }}
                >
                  <form
                    method="POST"
                    style={{
                      color: 'rgb(0, 0, 0)',
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '0',
                      height: 'auto',
                      position: 'relative',
                      width: '100%'
                    }}
                  >
                    <input
                      autoCapitalize="off"
                      autoComplete="off"
                      autoCorrect="off"
                      className="v1 framer-custom-input"
                      defaultValue=""
                      name="email"
                      placeholder="nom@exemple.com"
                      spellCheck="false"
                      style={{
                        WebkitAppearance: 'none',
                        appearance: 'none',
                        background: 'rgba(255, 255, 255, 0.1)',
                        border: 'none',
                        borderRadius: '10px',
                        color: 'rgb(255, 255, 255)',
                        fontFamily: '"Figtree", "Figtree Placeholder", sans-serif',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        height: 'auto',
                        letterSpacing: '0em',
                        lineHeight: '1.2em',
                        outline: 'none',
                        padding: '10px 39px 10px 12px',
                        width: '100%'
                      }}
                      type="email"
                    />
                    <div
                      style={{
                        bottom: '5px',
                        position: 'absolute',
                        right: '5px',
                        top: '5px'
                      }}
                    >
                      <input
                        style={{
                          WebkitAppearance: 'none',
                          appearance: 'none',
                          background: 'rgb(255, 255, 255)',
                          border: 'none',
                          borderRadius: '5px',
                          boxShadow: 'none',
                          color: 'rgb(0, 0, 0)',
                          cursor: 'pointer',
                          fontFamily: '"Inter", "Inter Placeholder", sans-serif',
                          fontSize: '14px',
                          fontStyle: 'normal',
                          fontWeight: '600',
                          height: '100%',
                          letterSpacing: '0em',
                          lineHeight: '1.2em',
                          outline: 'none',
                          padding: '0px 0px 0px 0px',
                          textAlign: 'center',
                          width: '27px',
                          zIndex: '1'
                        }}
                        type="submit"
                        value="->"
                      />
                    </div>
                  </form>
                </div>
              </div>
              <div className="ssr-variant hidden-u21snj hidden-vh577v hidden-72rtr7">
                <div
                  style={{
                    '--framer-custom-placeholder-color': 'rgba(255, 255, 255, 0.4)',
                    alignItems: 'center',
                    display: 'flex',
                    height: '100%',
                    justifyContent: 'center',
                    position: 'relative',
                    width: '100%'
                  }}
                >
                  <form
                    method="POST"
                    style={{
                      color: 'rgb(0, 0, 0)',
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '0',
                      height: 'auto',
                      position: 'relative',
                      width: '100%'
                    }}
                  >
                    <input
                      autoCapitalize="off"
                      autoComplete="off"
                      autoCorrect="off"
                      className="v1 framer-custom-input"
                      defaultValue=""
                      name="email"
                      placeholder="nom@exemple.com"
                      spellCheck="false"
                      style={{
                        WebkitAppearance: 'none',
                        appearance: 'none',
                        background: 'rgba(255, 255, 255, 0.1)',
                        border: 'none',
                        borderRadius: '10px',
                        color: 'rgb(255, 255, 255)',
                        fontFamily: '"Figtree", "Figtree Placeholder", sans-serif',
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        height: 'auto',
                        letterSpacing: '0em',
                        lineHeight: '1.2em',
                        outline: 'none',
                        padding: '14px 48px 14px 12px',
                        width: '100%'
                      }}
                      type="email"
                    />
                    <div
                      style={{
                        bottom: '6px',
                        position: 'absolute',
                        right: '6px',
                        top: '6px'
                      }}
                    >
                      <input
                        style={{
                          WebkitAppearance: 'none',
                          appearance: 'none',
                          background: 'rgb(255, 255, 255)',
                          border: 'none',
                          borderRadius: '4px',
                          boxShadow: 'none',
                          color: 'rgb(0, 0, 0)',
                          cursor: 'pointer',
                          fontFamily: '"Inter", "Inter Placeholder", sans-serif',
                          fontSize: '16px',
                          fontStyle: 'normal',
                          fontWeight: '700',
                          height: '100%',
                          letterSpacing: '0em',
                          lineHeight: '1.2em',
                          outline: 'none',
                          padding: '0px 0px 0px 0px',
                          textAlign: 'center',
                          width: '36px',
                          zIndex: '1'
                        }}
                        type="submit"
                        value="->"
                      />
                    </div>
                  </form>
                </div>
              </div>
              <div className="ssr-variant hidden-175z5sy hidden-u21snj hidden-72rtr7">
                <div
                  style={{
                    '--framer-custom-placeholder-color': 'rgba(255, 255, 255, 0.4)',
                    alignItems: 'center',
                    display: 'flex',
                    height: '100%',
                    justifyContent: 'center',
                    position: 'relative',
                    width: '100%'
                  }}
                >
                  <form
                    method="POST"
                    style={{
                      color: 'rgb(0, 0, 0)',
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '0',
                      height: 'auto',
                      position: 'relative',
                      width: '100%'
                    }}
                  >
                    <input
                      autoCapitalize="off"
                      autoComplete="off"
                      autoCorrect="off"
                      className="v1 framer-custom-input"
                      defaultValue=""
                      name="email"
                      placeholder="nom@exemple.com"
                      spellCheck="false"
                      style={{
                        WebkitAppearance: 'none',
                        appearance: 'none',
                        background: 'rgba(255, 255, 255, 0.1)',
                        border: 'none',
                        borderRadius: '10px',
                        color: 'rgb(255, 255, 255)',
                        fontFamily: '"Figtree", "Figtree Placeholder", sans-serif',
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        height: 'auto',
                        letterSpacing: '0em',
                        lineHeight: '1.2em',
                        outline: 'none',
                        padding: '14px 48px 14px 12px',
                        width: '100%'
                      }}
                      type="email"
                    />
                    <div
                      style={{
                        bottom: '6px',
                        position: 'absolute',
                        right: '6px',
                        top: '6px'
                      }}
                    >
                      <input
                        style={{
                          WebkitAppearance: 'none',
                          appearance: 'none',
                          background: 'rgb(255, 255, 255)',
                          border: 'none',
                          borderRadius: '4px',
                          boxShadow: 'none',
                          color: 'rgb(0, 0, 0)',
                          cursor: 'pointer',
                          fontFamily: '"Inter", "Inter Placeholder", sans-serif',
                          fontSize: '16px',
                          fontStyle: 'normal',
                          fontWeight: '700',
                          height: '100%',
                          letterSpacing: '0em',
                          lineHeight: '1.2em',
                          outline: 'none',
                          padding: '0px 0px 0px 0px',
                          textAlign: 'center',
                          width: '36px',
                          zIndex: '1'
                        }}
                        type="submit"
                        value="->"
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="framer-qr6ve-container"
        style={{
          opacity: '0',
          transform: 'perspective(1200px)'
        }}
      >
        <section
          style={{
            MozMaskImage: 'linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 12.5%, rgba(0, 0, 0, 1) 87.5%, rgba(0, 0, 0, 0) 100%)',
            WebkitMaskImage: 'linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 12.5%, rgba(0, 0, 0, 1) 87.5%, rgba(0, 0, 0, 0) 100%)',
            display: 'flex',
            height: '100%',
            listStyleType: 'none',
            margin: '0',
            maskImage: 'linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 12.5%, rgba(0, 0, 0, 1) 87.5%, rgba(0, 0, 0, 0) 100%)',
            maxHeight: '100%',
            maxWidth: '100%',
            opacity: '0',
            overflow: 'hidden',
            padding: '10px',
            placeItems: 'center',
            textIndent: 'none',
            width: '100%'
          }}
        >
        </section>
      </div>
    </footer>
    <div className="framer-v6nd9y-container">
      <div
        style={{
          backgroundColor: 'rgba(0, 0, 0, 0)',
          borderRadius: '0',
          height: '100%',
          overflow: 'hidden',
          position: 'relative',
          transform: 'translateZ(0)',
          width: '100%'
        }}
      >
        <div id="One">
          <canvas
            style={{
              height: '100%',
              position: 'absolute',
              width: '100%'
            }}
           />
        </div>
      </div>
    </div>
  </div>
  <div
    className="framer-JILK8 framer-R9l6l"
    id="overlay"
  />
</div>
        </React.Fragment>
	);
};
export default Home;
