
import './App.css';
import Home from "./pages/Home";
import { Routes, Route } from "react-router-dom"
import React, { useEffect } from 'react';

function App() {
  
  useEffect(() => {
    // Add your script dynamically
    const script = document.createElement('script');
    script.src = '/assets/js/default_script0.PPXBJMFJ.js';
    script.type = 'module';
    script.async = true;
    document.body.appendChild(script);

    // const script1 = document.createElement('script');
    // script1.src = '/assets/js/jquery-3.5.1.min.dc5e7f18c84de3.js';
    // script1.async = true;
    // document.body.appendChild(script1);
    // Cleanup the script when the component unmounts
    return () => {
      document.body.removeChild(script);
      // document.body.removeChild(script1);
    };
  }, []);

  return (
    <div className="App">
    <Routes>
      <Route path="/" element={ <Home/> } />
    </Routes>
    </div>
  );
}

export default App;
